import React, { useEffect, useState } from "react";
import axios from "axios";
import { ActionIcon, Button, Modal, Text } from "@mantine/core";
import "./aa.css";
import { IconEdit, IconEye, IconFile, IconFolder, IconTrash } from "@tabler/icons-react";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { openDocument, removeDocument } from "../../utilities/apis/documents";
import { showNotification } from "@mantine/notifications";
import AddDocuments from "./AddDocuments";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";
import { Folder } from "tabler-icons-react";

const TreeNode = ({ node, handleReload }) => {
  const [expanded, setExpanded] = useState(false);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(0);
  const [corporate, setCorporate] = useState(false);
  const [data, setData] = useState(null);
  const [delId, setDelId] = useState(0);
  const currentUser = useSelector(selectUserData);

  const deleteDocument = async () => {
    const response = await apiWrapWithErrorWithData(
      removeDocument({ id: delId })
    );
    if (response.success) {
      showNotification({
        title: "delete document",
        message: "document deleted",
        color: "green",
      });
      setDeleteModal(false);
      setDelId(0);
      window.location.reload();
    } else {
      showNotification({
        title: "delete document",
        message: "document not deleted",
        color: "red",
      });
    }
  };
  const toggleExpand = () => setExpanded(!expanded);
  const viewDocuemnt = async (url) => {
    const response = await apiWrapWithErrorWithData(
      openDocument({ hostedLink: url })
    );
    if (response.success) {
      window.open(`${response.signedUrl}`, "_blank");
    }
  };

  return (
    <div className="tree-node">
      {/* <div><ActionIcon size={24} onClick={toggleExpand} color="blue" className="tree-node-label">
        {expanded ? <IconFolder  className="ml-2" size={24}/> : <IconFolder className="ml-2" size={24}/>} 
      </ActionIcon> <Text>{node.name || node.title}</Text></div> */}
      <div onClick={toggleExpand} className='flex'>
            <ActionIcon color='blue' className=''>
              {/* File icon should be used in case of file display */}
              <Folder />
            </ActionIcon>
            <Text size='xl' weight={100} className="ml-2">{node.name || node.title}</Text>
            {/* <Text size='xl' weight={100} className="ml-2">sevice agreement visible to all</Text> */}
          </div>

      {/* Display documents */}
      {expanded && node.documents && node.documents.length > 0 && (
        <div className="documents-list">
          {node.documents.map((doc) =>
           (
            <div key={doc.key} className="document-item">
              <div className="flex justify-between ml-4">
                <div className="flex gap-2">
                  <ActionIcon color="blue">
                  <IconFile  size={24} />
                  </ActionIcon>
                
                <Text className="text-xl">{doc.title}</Text>
                </div>
                <div className="flex mr-4 gap-4">
                  <ActionIcon
                    color="#46BDE1"
                    onClick={() => viewDocuemnt(doc.url)}
                  >
                    <IconEye color="#46BDE1"  size={40} />
                  </ActionIcon>
                  {currentUser.loginAs.id === 4 &&
                  <>                
                  <ActionIcon
                    onClick={() => {
                      setDelId(doc.id);
                      setDeleteModal(true);
                    }}
                  >
                    <IconTrash color="red" size={40} />
                  </ActionIcon>
                  <ActionIcon
                    onClick={() => {
                      setSelectedFile(doc.id);
                      setData(doc);
                      setCorporate(doc.corporate);
                      setModal(true);
                    }}
                  >
                    <IconEdit color="#46BDE1"  size={40} />
                  </ActionIcon></>}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Recursively display subcategories and subsubcategories */}
      {expanded &&
        node.subCategories &&
        Object.keys(node.subCategories).map((sub) => (
          <TreeNode
            key={sub}
            node={{ name: sub, ...node.subCategories[sub] }}
          />
        ))}

      {expanded &&
        node.subsubCategories &&
        Object.keys(node.subsubCategories).map((subsub) => (
          <TreeNode
            key={subsub}
            node={{ name: subsub, ...node.subsubCategories[subsub] }}
          />
        ))}

      <Modal
        title="delete document"
        opened={deleteModal}
        onClose={() => {
          setDelId(0);
          setDeleteModal(false);
        }}
      >
        <div>
          <h2 className="text-2xl text-500 font-500 mb-4 text-center">
            are you sure ?
          </h2>
          <div className="flex justify-between">
            <Button
              variant="outline"
              color="orange"
              onClick={() => {
                setDelId(0);
                setDeleteModal(false);
              }}
            >
              cancel
            </Button>
            <Button onClick={deleteDocument} color="red">
              delete
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        opened={modal}
        onClose={() => setModal(false)}
        title="update document"
        size={"90%"}
      >
        <AddDocuments
          data={data}
          handleReload={handleReload}
          corporate={corporate}
          selectedFile={selectedFile}
          modal={modal}
          setModal={setModal}
        />
      </Modal>
    </div>
  );
};

export default TreeNode;
