import { Select, Skeleton } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getAgreementByStatus,
  getTotalAgreementsList,
} from "../../utilities/apis/agreements";
import { loadingStates } from "../../utilities/utilities";

function AgreementGraph({ configs, year, setYear }) {
  const allMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const agreementByMonth = configs?.data?.agreementByMonth || [];
  const data = allMonths.map((month) => {
    const monthData = agreementByMonth.find((el) => el.month === month);
    return monthData ? monthData["_count"].id : 0;
  });

  return (
    <div style={{ width: "50%" }} className="pr-2">
      <div
        className="border-solid border-gray-100 border-2 rounded-lg mt-4"
        style={{
          border: "none",
          boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1)",
          width: "100%",
          padding: "2%",
        }}
      >
        <Select
          value={year + ""}
          className="w-40 h-8"
          data={["2022","2023", "2024", "2025", "2026", "2027", "2028", "2029"]}
          onChange={(val) => setYear(parseInt(val))}
        />
        <div className="mt-2 ml-4">monthly stats</div>
        {configs.loading === loadingStates.LOADING && (
          <Skeleton style={{ height: "200px" }} className="mt-4 mx-8" visible />
        )}
        {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
          configs.data.agreementByMonth && (
            <div
              className="w-full"
              style={{
                height: "200px",
                width: "100%",
              }}
            >
              <Bar
                data={{
                  labels: allMonths,
                  datasets: [
                    {
                      label: "agreements",
                      backgroundColor: "rgb(70, 189, 225)",
                      barThickness: 30,
                      barPercentage: 0.5,
                      borderRadius: 10,
                      data: data,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "top",
                      display: false,
                    },
                    datalabels: {
                      anchor: "end",
                      align: "end",
                      formatter: (value) => (value > 0 ? value : ""),
                      color: "#fff",
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: "agreements",
                      },
                      grid: {
                        display: false,
                      },
                      ticks: {
                        display: false,
                      },
                    },
                    x: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: "month",
                      },
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default AgreementGraph;
