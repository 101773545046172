import React, { useEffect, useState } from "react";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getInboxEmails, getOutboxEmails } from "../../utilities/apis/emails";
import { PageSizes } from "pdf-lib";
import EmailList from "../../components/EmailManager/EmailList";
import ViewEmail from "../../components/EmailManager/ViewEmail";
import { Pagination, Select } from "@mantine/core";

const Outbox = () => {
  const [emailData, setEmailData] = useState(null);
  const [emailSelected, setEmailSelected] = useState(null);
  const [configs, setConfigs] = useState({
    loading: true,
    page: 1,
    pageSize: 10,
    count: 0,
  });
  useEffect(() => {
    const getEmails = async () => {
      const response = await apiWrapWithErrorWithData(
        getOutboxEmails({
          page: configs.page,
          pageSize: configs.pageSize,
        })
      );
      if (response.success) {
        setEmailData(response.emails);
        setConfigs({
            ...configs, 
            loading: false,
            count: response.count,
        });
      }
    };
    getEmails();
  }, [configs.page, configs.pageSize]);
  console.log("emails: ", emailData);
  return (
    <div className="p-3">
      <div className="flex w-full h-full">
        <div className="w-2/5">
          {emailData &&
            emailData.map((email) => (
              <div
                onClick={() => setEmailSelected(email)}
                className="p-1"
                key={email.id}
              >
                <EmailList
                  email={email.from}
                  subject={email.subject}
                  time={email.sentAt}
                  
                />
              </div>
            ))}
        </div>
        <div className="w-3/5 py-2 px-4 border-l-2">
          {emailSelected && (
            <ViewEmail
              subject={emailSelected.subject}
              from={emailSelected.from}
              to={emailSelected.to}
              attachments={emailSelected.attachments}
              body={emailSelected.body}
              time={emailSelected.sentAt}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center my-3 mx-auto">
        <Pagination
          page={configs.page}
          onChange={(page) => setConfigs({ ...configs, page: page })}
          total={Math.ceil(configs.count / 10)}
        />
        <Select
        className="w-20 ml-5 "
        onChange={(value)=>setConfigs({...configs, pageSize: value})}
          data={[
            {
              label: "10",
              value: 10,
            },
            {
              label: "20",
              value: 20,
            },
            {
              label: "50",
              value: 50,
            },
          ]}
          value={configs.pageSize}
        />
      </div>
    </div>
  );
};

export default Outbox;
