import React from 'react';
import { Button } from '@mantine/core';
import styles from './DashBoardNotificationBox.module.css';
import { formatDate, formatTime } from '../../utilities/utilities';

function DashBoardNotificationBox({
  backgroundColor, date, module, notificationText, btnName, onBtnClick, isDeleted, seen
}) {
  const bg = {
    backgroundColor,
  };
  // console.log(isDeleted);
  
  return (
    <div style={bg} className={`${styles.bgRed} pl-4 my-1`}>
      <div className={seen? `${styles.topBox} px-6 pt-2 flex justify-between bg-white`: `${styles.topBox} px-6 pt-2 flex justify-between bg-blue-100`}>
        <div className={`flex`}>
          <div className={`${styles.task}`} style={{ marginRight:'15px'}}>{module}</div>
          <div className={`${styles.heading} my-2 mr-8`}>{notificationText}</div>
        </div>
        <div className='flex'>
          <div className="flex flex-row mr-4 items-center" style={{ width: '100px'}}>
            <img src="/assets/images/calendar.svg" alt="calendar" />
            <div className={`${styles.detail} ml-2`}>
              {formatDate(date)}
            </div>
          </div>
          <div className="flex flex-row mr-4 items-center" style={{ width: '80px'}}>
            <img src="/assets/images/clock.svg" alt="clock" />
            <div className={`${styles.detail} ml-2`}>{formatTime(date)}</div>
          </div>
          <div className="flex justify-center items-center" >
            <Button
              disabled={isDeleted}
              onClick={onBtnClick}
              compact
              style={{
                backgroundColor: isDeleted ? '#D0D0D0' : '#46BDE1',
                borderRadius: '0.5rem',
                color: isDeleted ? '#383838': '#F5F5F5',
              }}
            >
              {btnName}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoardNotificationBox;
