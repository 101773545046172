/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Select, TextInput } from '@mantine/core';
import flat from 'flat';
import { BeatLoader } from 'react-spinners';
import { DatePicker } from '@mantine/dates';
import { formatISO, parseISO } from 'date-fns';
import { loadingStates } from '../../utilities/utilities';
import colors from '../../utilities/design';
import { showNotification } from '@mantine/notifications';
import { getBussinessUnit } from '../../utilities/apis/serviceProvider';

export const INDIA_STATES_UNIONS = [
  { label: 'Andhra Pradesh', value: 'Andhra Pradesh' },
  { label: 'Karnataka', value: 'Karnataka' },
  { label: 'Andaman and Nicobar Islands', value: 'Andaman and Nicobar Islands' },
  { label: 'Arunachal Pradesh', value: 'Arunachal Pradesh' },
  { label: 'Assam', value: 'Assam' },
  { label: 'Bihar', value: 'Bihar' },
  { label: 'Chandigarh', value: 'Chandigarh' },
  { label: 'Chattisgarh', value: 'Chattisgarh' },
  { label: 'Dadra and Nagar Haveli', value: 'Dadra and Nagar Haveli' },
  { label: 'Daman and Diu', value: 'Daman and Diu' },
  { label: 'Delhi', value: 'Delhi' },
  { label: 'Goa', value: 'Goa' },
  { label: 'Gujarat', value: 'Gujarat' },
  { label: 'Haryana', value: 'Haryana' },
  { label: 'Himachal Pradesh', value: 'Himachal Pradesh' },
  { label: 'Jammu and Kashmir', value: 'Jammu and Kashmir' },
  { label: 'Jharkhand', value: 'Jharkhand' },
  { label: 'Kerala', value: 'Kerala' },
  { label: 'Lakshadweep Islands', value: 'Lakshadweep Islands' },
  { label: 'Madhya Pradesh', value: 'Madhya Pradesh' },
  { label: 'Maharashtra', value: 'Maharashtra' },
  { label: 'Manipur', value: 'Manipur' },
  { label: 'Meghalaya', value: 'Meghalaya' },
  { label: 'Mizoram', value: 'Mizoram' },
  { label: 'Nagaland', value: 'Nagaland' },
  { label: 'Odisha', value: 'Odisha' },
  { label: 'Pondicherry', value: 'Pondicherry' },
  { label: 'Punjab', value: 'Punjab' },
  { label: 'Rajasthan', value: 'Rajasthan' },
  { label: 'Sikkim', value: 'Sikkim' },
  { label: 'Tamil Nadu', value: 'Tamil Nadu' },
  { label: 'Telangana', value: 'Telangana' },
  { label: 'Tripura', value: 'Tripura' },
  { label: 'Uttar Pradesh', value: 'Uttar Pradesh' },
  { label: 'Uttarakhand', value: 'Uttarakhand' },
  { label: 'West Bengal', value: 'West Bengal' },
];

const AgreementForm2 = ({
    id, form, setUiConfigs, uiConfigs, save, initialState
}) => {
  const agreementFormJson = flat.unflatten(form.values);
  console.log({ agreementFormJson });
   const [businessUnitsOptions, setBusinessUnitsOptions] = useState([]);

  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const { data } = await getBussinessUnit();

        if (data) {
          setBusinessUnitsOptions(
            data.businessunitList.map((unit) => ({
              value: unit.id+ "",
              label: unit.name,
            })) || []
          );
        } else {
          console.error("Invalid business unit response format:", data);
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };
    fetchBusinessUnits();
  }, []);

  const reminderLabels = {
    15:'15 days',
    30:'30 days',
    45:'45 days',
    60:'60 days',
  };

  const validate = () => {
    const keys = {};
      if (!agreementFormJson.dateOfContract
          || isNaN(parseISO(
              agreementFormJson.dateOfContract
          ).getTime())) {
        keys['dateOfContract'] = 'please enter date.';
      }

      // if(!agreementFormJson.location) {
      //   keys['location'] = 'Please enter location.';
      // }
        if (!agreementFormJson.businessUnit) {
          keys["businessUnit"] = "please enter business Unit.";
        }
     
      if((!agreementFormJson.noOfYears || isNaN(agreementFormJson.noOfYears) || parseInt(agreementFormJson.noOfYears, 10) <= 0) && (!agreementFormJson.noOfMonths || isNaN(agreementFormJson.noOfMonths) || parseInt(agreementFormJson.noOfMonths, 10) <= 0)){
        keys['noOfYears'] = 'please check value.';
        keys['noOfMonths'] = 'please check value.';
      }
    

    if((Object.keys(keys)).length !== 0){
      form.setErrors(keys);
      showNotification(({
        color: 'red',
        title: 'agreement form',
        message: 'please check all fields are filled properly.',
      }));
    }
    return (Object.keys(keys)).length === 0;

  };

  const savePrev = async () => {
    if (true) {
     
      setUiConfigs({
        ...uiConfigs,
        currentStep: 0,
      });
    }
  };

  const saveNext = async () => {
    if (validate()) {
      await save({...form.values });
      setUiConfigs({
        ...uiConfigs,
        currentStep: 2,
      });
      // navigate(`/app/agreements/details/${id}`);
    }
  };

  return (
    <div className="w-full flex flex-col my-4">
      <div style={{ width: "50%", marginLeft: "5%" }}>
        <DatePicker
          required
          style={{ marginBottom: "20px" }}
          label="starting date"
          placeholder="select starting date"
          {...{
            ...form.getInputProps("dateOfContract"),
            onChange: (val) => {
              if (val && val.getTime) {
                form.getInputProps("dateOfContract").onChange(formatISO(val));
              } else {
                form.getInputProps("dateOfContract").onChange(null);
              }
            },
            value: form.values["dateOfContract"]
              ? parseISO(form.values.dateOfContract)
              : null,
          }}
        />

        <div className="flex flex-row" style={{ marginBottom: "20px" }}>
          <Select
          required
            label="duration"
            placeholder="select years"
            data={[
              ...Array.from({ length: 11 }, (_, i) => ({
                value: `${i}`,
                label: `${i} year${i === 1 ? "" : "s"}`,
              })),
              { value: "100", label: "100 years" },
            ]}
            {...form.getInputProps("noOfYears")}
            style={{ width: "160px", marginRight: "20px" }}
          />
          <Select
            label="   "
            placeholder="select months"
            data={[
              { value: "0", label: "0 month" },
              ...Array.from({ length: 12 }, (_, i) => ({
                value: `${i + 1}`,
                label: `${i + 1} month${i === 0 || i === 1 ? "" : "s"}`,
              })),
            ]}
            {...form.getInputProps("noOfMonths")}
            style={{ width: "160px" }}
          />
        </div>
        <TextInput
        style={{ marginBottom: "20px" }}
          type='number'
          label="annual agreement value (₹)"
          placeholder='annual agreement value'
          {...form.getInputProps("agreementValue")}
           />
        <Select
          style={{ marginBottom: "20px" }}
          placeholder="reminder time"
          label="renewal reminder time (in days)"
          data={[
            { value: 15, label: reminderLabels["15"] },
            { value: 30, label: reminderLabels["30"] },
            { value: 45, label: reminderLabels["45"] },
            { value: 60, label: reminderLabels["60"] },
          ]}
          {...form.getInputProps("reminderTimeInDays")}
        />
        {/* <Select
          style={{ marginBottom: "20px" }}
          required
          searchable
          placeholder="Enter location"
          label="Location"
          data={[...INDIA_STATES_UNIONS]}
          {...form.getInputProps("location")}
        /> */}
        <Select
          required
          searchable
          placeholder="select business unit"
          label="business unit"
          data={businessUnitsOptions}
          {...form.getInputProps("businessUnit")}
          dropdownPosition="bottom"
        />
        {/* <TextInput required label="Location" placeholder='Enter location' {...form.getInputProps('location')}/> */}
      </div>
      <div className="flex flex-row justify-between">
        {uiConfigs.loading === loadingStates.LOADING ? (
          <BeatLoader color={colors.primary} size={10} />
        ) : (
          <>
            <Button
              className="max-w-xs mt-20"
              onClick={savePrev}
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              previous
            </Button>
            <Button
              className="max-w-xs mt-20"
              onClick={saveNext}
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              next
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AgreementForm2;
