/** @format */

import React, { useEffect, useState } from "react";
import { ActionIcon, Badge, Modal, Table, Text } from "@mantine/core";
import { Eye, History, Plus } from "tabler-icons-react";
import { getRelatedAgreement } from "../../utilities/apis/agreements";
import { formatDate } from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getStatusColor, getStatusText } from "../../utilities/enums";
import AgreementAuditLogModal from "./AgreementAuditLogModal";

const RelatedAgreementModal = ({ agreementId }) => {
  const [relatedAgreementData, setRelatedAgreementData] = useState({
    agreements: null,
    loading: true,
    count: 0,
  });
  const [showModalRelated, setShowModalRelated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const[relatedId, setRelatedId] = useState(null);
  const[showAuditId, setShowAuditId] = useState(null);

  const onViewClick = (agreementviewId) => {
    // const url =
    //   process.env.PUBLIC_URL +
      Navigate(`/app/agreements_manager/agreements/details/${agreementviewId}`);
    // window.open(url, "noopener,noreferrer");
  };
  useEffect(() => {
    const fetchRelatedAgreement = async () => {
      try {
        const response = await apiWrapWithErrorWithData(
          getRelatedAgreement({ agreementId })
        );
        console.log("Reelated Agreement Response:", response);
        if (response.success) {
          setRelatedAgreementData({
            loading: false,
            count: response.count,
            agreements: response.dataArray,
          });
        }
      } catch (error) {
        console.error("Error fetching audit log:", error);
      }
    };

    fetchRelatedAgreement();
  }, [agreementId]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between my-4">
          <Text>{`related agreement/s for agreement-${agreementId}`}</Text>
        </div>
      </div>

      <br />

      {!relatedAgreementData?.loading && (
        <div
          className="flex flex-col"
          style={{
            overflowX: "scroll",
            marginTop: "15px",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <Table striped>
            <thead>
              <tr>
                <th>ain</th>
                <th>type</th>
                <th>business unit</th>
                <th>status</th>
                <th>second party</th>
                <th>contract date</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {relatedAgreementData.agreements.map((row) => (
                <tr key={row.id}>
                  <td>{`agreement-${row.id}`}</td>
                  <td>{row.typeOfAgreement}</td>
                  <td>{row.mapBusinessunit}</td>

                  <td>
                    <Badge color={getStatusColor(row.status)}>
                      {getStatusText(row.status)}
                    </Badge>
                  </td>

                  <td>{row.secondParty?.name || "-"}</td>
                  <td>{formatDate(row.dateOfContract)}</td>

                  <td>
                    <div className="flex flex-row">
                      <ActionIcon
                        onClick={() => {
                          onViewClick(row.id);
                        }}
                        color="white"
                      >
                        <Eye size={24} />
                      </ActionIcon>
                      {/* )} */}
                    </div>
                  </td>
                  <td>
                    <div className="flex flex-row ">
                      <ActionIcon
                        onClick={() => {
                          setRelatedId(row.id);
                          setShowModalRelated(true);

                        }}
                        color="white"
                      >
                        <Plus size={24} />
                      </ActionIcon>
                      {/* )} */}
                    </div>
                  </td>
                  <td>
                    <div className="flex flex-row mr-2">
                      <ActionIcon
                        onClick={() => {
                          setShowAuditId(row.id);
                          setShowModal(true);
                        }}
                        color="white"
                      >
                        <History size={24} />
                      </ActionIcon>
                      {/* )} */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {showModalRelated && (
        <Modal
          overflow="inside"
          opened
          onClose={()=>setShowModalRelated(false)} // Call the handler on modal close
          size="calc(80vw)"
        >
          <RelatedAgreementModal agreementId={relatedId} />
        </Modal>
      )}
      {showModal && (
        <Modal
          overflow="inside"
          opened
          onClose={()=>setShowModal(false)} // Call the handler on modal close
          size="calc(80vw)"
        >
          <AgreementAuditLogModal agreementId={showAuditId} />
        </Modal>
      )}
    </>
  );
};

export default RelatedAgreementModal;
