import React from "react";
import { formatDate, formatTime } from "../../utilities/utilities";
import { ActionIcon, Anchor, Button, Progress, Text } from "@mantine/core";
import { ArrowBigLeftLine, Trash } from "tabler-icons-react";
import useMultiFileUpload from "../../hooks/useMultiFileUpload";
import NewDocumentUploader from "../NewDocumentUploader/NewDocumentUploader";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { openDocument } from "../../utilities/apis/documents";
import { showNotification } from "@mantine/notifications";

const ViewEmail = ({ subject, to, from, body, time, attachments }) => {
  const viewDocuemnt = async (url) => {
    
      const response = await apiWrapWithErrorWithData(
        openDocument({ hostedLink: url })
      );
      if (response.success) {
        window.open(`${response.signedUrl}`, "_blank");
      }
    
  };
  return (
    <div className="pl-3">
      <h2 className="text-xl font-normal">{subject}</h2>
      <div className="my-3">
        <div className="flex justify-between">
          <h3 className="text-base font-semibold">{from}</h3>
          <div className="flex">
            <p className="text-sm mr-2">
              {formatDate(time)} {formatTime(time)}
            </p>
            <ActionIcon>
              <ArrowBigLeftLine />
            </ActionIcon>
          </div>
        </div>
        <p className="text-sm">to: {to}</p>
      </div>

      <div className="my-6" dangerouslySetInnerHTML={{ __html: body.replace(/\r\n/g, '<br>') }}></div>
      <div className="mt-4 flex flex-col">
        <Text className="mb-2">Attachements</Text>
        {attachments.map((file) => (
          <div className="flex items-center justify-center w-full mx-6">
            <div className="flex w-full mt-4">
              <img
                //   className="m-auto"
                src="/assets/images/downloadFileLogo.svg"
                alt="file upload"
              />
              <div className=" pl-3 w-10/12 ">
                <div className="">
                  <div className="text-xl">{file.fileName}</div>
                </div>
                <div className="mt-3">
                  <Progress color={"teal"} size="sm" value={100} />
                </div>
              </div>

              <div className="flex items-center">
                {/* <Anchor
                  // onClick={downloadFile(index)}
                  className="mx-3 "
                  color="#46BDE1"
                  // disabled={file.downloading === loadingStates.LOADING}
                >
                  <Text>{file.fileName}</Text>
                </Anchor> */}
                <Button
                  className="mx-3 w-60"
                  color="#46BDE1"
                  variant="outline"
                  onClick={()=>viewDocuemnt(file.hostedLink)}
                >
                  Open
                </Button>
                {/* <ActionIcon color="red">
                    <Trash
                      onClick={() => setFile(null)}
                      // onClick={deleteFile(index)}
                    />
                  </ActionIcon> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewEmail;
