/** @format */

import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import MainWrapper from "./MainWrapper";
import LazyLoading from "../components/LazyLoading/LazyLoading";
// import LandingPage from './LandingPage/LandingPage';
import MainApp from "./MainApp/MainApp";
import Protected from "../components/Protected/Protected";
import RequestNoticeView from "./LegalNotices/RequestNoticeView";
import NoticeDetailView from "./LegalNotices/NoticeDetailView";
import Dashboard from "./Dashboard";
import CaseCreatePage from "./CaseManagement/CaseCreatePage";
import CaseDetailsPage from "./CaseManagement/CaseDetailsPage";
import CaseListingPage from "./CaseManagement/CaseListingPage";
import Tasks from "./Tasks";
import AgreementsListingPage from "./AgreementsManager/AgreementsListingPage";
import AgreementCreatePage from "./AgreementsManager/AgreementCreatePage";
import AgreementBulkCreate from "./AgreementsManager/BulkAgreement/AgreementBulkCreate";
import CaseFormNew from "./CaseFormNew";
import DashboardNew from "./DashboardNew";
import NotificationsPage from "./NotificationsPage";
import NoticeRequest from "./NoticeRequest/NoticeRequest";
import NoticesListing from "./LegalNotices/NoticesListing";
import NoticeCreatePage from "./LegalNotices/NoticeCreation/NoticeCreatePage";
import NoticeDetailsPage from "./LegalNotices/NoticeDetailsPage";
import TemplatesList from "../components/TemplateManager/TemplatesList";
import TemplateForm from "../components/TemplateManager/TemplateForm";
import NewLandingPageRedirect from "./NewLandingPageRedirect";
import LandingPage from "./LandingPage/LandingPage";
import ServiceProviderList from "./ServiceProvider/ServiceProviderList";
import BudgetManager from "./BudgetManager/BudgetManager";
import LoginPage from "./LoginPage";
// import ServiceProviderDetail from '../components/ServiceProviderDetail/ServiceProviderDetail';
import ServiceProviderDetails from "./ServiceProvider/ServiceProviderDetails";
import TemplateEditor from "../components/TemplateManager/TemplateEditor";
import Search from "./Search";
import Blogs from "./Blogs/Blogs";
import CreateBlogs from "./Blogs/CreateBlogs";
import Clause from "./Clause/Clause";
import CreateClause from "./Clause/CreateClause";
import Document from "./DocumentSection/Document";
import LandingPageAfterLogin from "./LandingPageAfterLogin";
import AltMainApp from "./MainApp/AltMainApp";
import MainAppAgreementsManager from "./MainAppAgreementsManager/MainAppAgreementsManager";
import DashboardAgreementsManager from "./DashboardAgreementsManager";
import AgreementsInCollabrationListingPage from "./AgreementsManager/AgreementsInCollabrationListingPage";
import AgreementsAllStatus from "./AgreementsManager/AgreementsAllStatus";
import AgreementsInApproval from "./AgreementsManager/AgreementsInApproval";
import AgreementsSigned from "./AgreementsManager/AgreementsSigned";

// import AgreementDetailsPage from './AgreementsManager/AgreementDetailsPage';
const AgreementDetailsPage = lazy(() =>
  import("./AgreementsManager/AgreementDetailsPage")
);
import AddOrganisationPage from "./../components/AddOrganisation/AddOrganisationPage";
import AddOrganisationDetail from "../components/AddOrganisation/AddOrganisationDetail";
import AgreementsInSigning from "./AgreementsManager/AgreementInSigning";
import AgreementsExpired from "./AgreementsManager/AgreementsExpired";
import TasksPage from "./TasksPage";
import AgreementsListingPage1 from "./AgreementsManager/AgreementsListingPage1";
import DocsLocker from "./AgreementsManager/DocsLocker";
import ReviewSideNav from "./AgreementsManager/ReviewSideNav";
import SignatureSideNav from "./AgreementsManager/SignatureSideNav";
import AgreementsListPage from "./AgreementsManager/AgreementListPage";
import CreateNewLegacyAgreement from "./AgreementsManager/CreateNewLegacyAgreement";
import CreateBulkLegacyAgreement from "./AgreementsManager/BulkLegacyAgreements/createBulkLegacyAgreement";
import ForgotPassword from "./ForgotPassword";
import UpdatePassword from "./UpdatePassword";
import MyDocuments from "./AgreementsManager/myDocuments";
import Inbox from "./emailmanagement/Inbox";
import Outbox from "./emailmanagement/Outbox";



const RouteHandler = () => (
  <MainWrapper>
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route
          path="/"
          exact
          element={
            process.env.REACT_APP_ENV === "LOCALHOST" ? (
              <LandingPage />
            ) : (
              <NewLandingPageRedirect />
            )
          }
        />
        {/* <Route
          path="/"
          exact
          element={
            process.env.REACT_APP_ENV === "LOCALHOST" ? (
              <LandingPage />
            ) : (
              <NewLandingPageRedirect />
            )
          }
        /> */}
        <Route path="/app/login/" exact element={<LoginPage />} />
        <Route path="/app/forgotpassword" exact element={<ForgotPassword />} />
        <Route path="/app/updatepassword" exact element={<UpdatePassword />} />
        <Route path="/request-notice" exact element={<NoticeRequest />} />
        {/* <Route
          path="/app/case-new-form"
          exact
          element={
            <Protected>
              <MainApp appContent={<CaseFormNew />} />
            </Protected>
          }
        />
        <Route
          path="/app-old"
          exact
          element={
            <Protected>
              <MainApp appContent={<Dashboard />} />
            </Protected>
          }
        />
        <Route
          path="/app"
          exact
          element={
            <Protected>
              <MainApp appContent={<DashboardNew />} />
            </Protected>
          }
        /> */}
        <Route
          path="/app/choose_manager"
          exact
          element={
            <Protected>
              <LandingPageAfterLogin />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager
                appContent={<DashboardAgreementsManager />}
              />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/review-status"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager
                appContent={<AgreementsInCollabrationListingPage />}
              />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/total-agreements"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<AgreementsAllStatus />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/renewals"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<AgreementsExpired />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/tasks/pending"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<TasksPage status={"PENDING"} />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/emails/inbox"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<Inbox />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/emails/outbox"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<Outbox />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/tasks/actioned"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<TasksPage status={"ACTIONED"} />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/approval-status"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<AgreementsInApproval />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/signed"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<AgreementsSigned />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/create-new-agreement"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager
                appContent={<AgreementsListingPage1 />}
              />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/agreements-list"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<AgreementsListPage />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/docs-locker"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<DocsLocker />} />
            </Protected>
          }
        />
        <Route
          path="app/mydocuments"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager dm={true} appContent={<MyDocuments  />} />
            </Protected>
          }
        />
        <Route
          path="app/corporatedocuments"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager dm={true} appContent={<MyDocuments />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/review"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<ReviewSideNav />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/signature"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<SignatureSideNav />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/signature-status"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<AgreementsInSigning />} />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/service-providers/"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<ServiceProviderList />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/service-providers/add-businessunit"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<AddOrganisationPage />} />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/notifications"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<NotificationsPage />} />
            </Protected>
          }
        />
        <Route
          path="app/agreements_manager/add-businessunit/details/:serviceProviderId"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager
                appContent={<AddOrganisationDetail />}
              />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/agreements/details/:agreementId"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager
                appContent={
                  <Suspense fallback={<BeatLoader />}>
                    <AgreementDetailsPage />
                  </Suspense>
                }
              />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/agreements/new"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<AgreementCreatePage />} />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/agreements/new-legacy-agreement"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<CreateNewLegacyAgreement />} />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/agreements/bulk-legacy-agreement"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<CreateBulkLegacyAgreement />} />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/agreements/new-bulk"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<AgreementBulkCreate />} />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/agreements/edit/"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<AgreementCreatePage />} />
            </Protected>
          }
        />
        {/* <Route
          path="/app/search/:input"
          exact
          element={
            <Protected>
              <MainApp appContent={<Search />} />
            </Protected>
          }
        />
        <Route
          path="/app/notifications"
          exact
          element={
            <Protected>
              <MainApp appContent={<NotificationsPage />} />
            </Protected>
          }
        />
        <Route
          path="/app/tasks"
          exact
          element={
            <Protected>
              <MainApp appContent={<Tasks />} />
            </Protected>
          }
        />
        <Route
          path="/app/dispute-manager/edit-case"
          exact
          element={
            <Protected>
              <MainApp appContent={<CaseCreatePage />} />
            </Protected>
          }
        />
        <Route
          path="/app/dispute-manager/cases"
          exact
          element={
            <Protected>
              <MainApp appContent={<CaseListingPage />} />
            </Protected>
          }
        />
        <Route
          path="/app/dispute-manager/cases/details/:caseId"
          exact
          element={
            <Protected>
              <MainApp appContent={<CaseDetailsPage />} />
            </Protected>
          }
        />
        <Route
          path="/app/dispute-manager/legal-notices/create"
          exact
          element={
            <Protected>
              <MainApp appContent={<NoticeCreatePage />} />
            </Protected>
          }
        />
        <Route
          path="/app/dispute-manager/legal-notices/edit/"
          exact
          element={
            <Protected>
              <MainApp appContent={<NoticeCreatePage />} />
            </Protected>
          }
        />
        <Route
          path="/app/dispute-manager/legal-notices"
          exact
          element={
            <Protected>
              <MainApp appContent={<NoticesListing />} />
            </Protected>
          }
        />
        <Route
          path="/app/dispute-manager/legal-notices/details/:noticeId"
          exact
          element={
            <Protected>
              <MainApp appContent={<NoticeDetailsPage />} />
            </Protected>
          }
        />
        <Route
          path="/app/view/notice-request/:noticeRequestRef"
          exact
          element={
            <Protected>
              <MainApp appContent={<RequestNoticeView />} />
            </Protected>
          }
        />
        <Route
          path="/app/view/notice/:noticeRef"
          exact
          element={
            <Protected>
              <MainApp appContent={<NoticeDetailView />} />
            </Protected>
          }
        /> */}
        {/* <Route
          path="/app/templates"
          exact
          element={(
            <Protected>
              <MainApp appContent={(
                <TemplatesList
                  showEditButton
                  showViewButton={false}
                />
                )}
              />
            </Protected>
          )}
        /> */}
        <Route
          path="/app/templates/agreement"
          exact
          element={
            <Protected>
              <MainApp
                appContent={
                  <TemplatesList
                    templateType="Agreements"
                    showEditButton
                    showViewButton={false}
                  />
                }
              />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/templates/agreement"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager
                appContent={
                  <TemplatesList
                    templateType="Agreements"
                    showEditButton
                    showViewButton={false}
                  />
                }
              />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/templates/clause"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<Clause />} />
            </Protected>
          }
        />
        <Route
          path="/app/agreements_manager/notice"
          exact
          element={
            <Protected>
              <MainApp
                appContent={
                  <TemplatesList
                    templateType="Notices"
                    showEditButton
                    showViewButton={false}
                  />
                }
              />
            </Protected>
          }
        />
        {/* <Route
          path="/app/templates/notice"
          exact
          element={
            <Protected>
              <MainApp
                appContent={
                  <TemplatesList
                    templateType="Notices"
                    showEditButton
                    showViewButton={false}
                  />
                }
              />
            </Protected>
          }
        /> */}
        {/* <Route
          path="/app/templates/document"
          exact
          element={
            <Protected>
              <MainApp
                appContent={
                  <Document />
                }
              />
            </Protected>
          }
        /> */}
        {/* <Route
          path="/app/templates/clause"
          exact
          element={
            <Protected>
              <MainApp appContent={<Clause />} />
            </Protected>
          }
        />
        
        <Route
          path="/app/templates/agreements/new"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<TemplateForm />} />
            </Protected>
          }
        /> */}
        <Route
          path="/app/templates/clause/:id"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<CreateClause />} />
            </Protected>
          }
        />
        <Route
          path="/app/templates/agreements_manager/agreements/new"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<TemplateForm />} />
            </Protected>
          }
        />
        <Route
          path="/app/templates/edit/template/:templateId"
          exact
          element={
            <Protected>
              <MainAppAgreementsManager appContent={<TemplateEditor />} />
            </Protected>
          }
        />
        <Route
          path="/app/service-providers/details/:serviceProviderId"
          exact
          element={
            <Protected>
              <MainApp appContent={<ServiceProviderDetails />} />
            </Protected>
          }
        />
        
        
        {/* <Route
          path="/app/templates/notices/new"
          exact
          element={
            <Protected>
              <MainApp appContent={<TemplateForm />} />
            </Protected>
          }
        />
        <Route
          path="/app/templates/edit/template/:templateId"
          exact
          element={
            <Protected>
              <MainApp appContent={<TemplateEditor />} />
            </Protected>
          }
        />
        <Route
          path="/app/agreements"
          exact
          element={
            <Protected>
              <MainApp appContent={<AgreementsListingPage />} />
            </Protected>
          }
        /> */}
        {/* 
        <Route
          path="/app/agreements/new"
          exact
          element={
            <Protected>
              <MainApp appContent={<AgreementCreatePage />} />
            </Protected>
          }
        />
        <Route
          path="/app/agreements/new-bulk"
          exact
          element={
            <Protected>
              <MainApp appContent={<AgreementBulkCreate />} />
            </Protected>
          }
        />
        <Route
          path="/app/agreements/edit/"
          exact
          element={
            <Protected>
              <MainApp appContent={<AgreementCreatePage />} />
            </Protected>
          }
        />
        <Route
          path="/app/service-providers/"
          exact
          element={
            <Protected>
              <MainApp appContent={<ServiceProviderList />} />
            </Protected>
          }
        />
        
        <Route
          path="/app/budget-manager/"
          exact
          element={
            <Protected>
              <MainApp appContent={<BudgetManager />} />
            </Protected>
          }
        />
        <Route
          path="/app/blog-manager"
          exact
          element={
            <Protected>
              <MainApp appContent={<Blogs />} />
            </Protected>
          }
        />
        <Route
          path="/app/blog-manager/create"
          exact
          element={
            <Protected>
              <MainApp appContent={<CreateBlogs />} />
            </Protected>
          }
        />
        <Route
          path="/app/blog-manager/edit/:blogId"
          exact
          element={
            <Protected>
              <MainApp appContent={<CreateBlogs />} />
            </Protected>
          }
        /> */}
      </Routes>
    </Suspense>
  </MainWrapper>
);
export default RouteHandler;
