import { Button, Modal, MultiSelect, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import AddDocuments from "../../components/documents/AddDocuments";
import { useLocation } from "react-router-dom";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getAllDocumentCategories,
  getTagWords,
} from "../../utilities/apis/documents";
import DocumentsTree from "../../components/documents/DocumentsTree";
import { selectUserData } from "../../redux/selectors";
import { useSelector } from "react-redux";

const MyDocuments = () => {
  const [modal, setModal] = useState(false);
  const [tab, setTab] = useState("my documents");
  const [selectedFile, setSelectedFile] = useState(0);
  const [corporate, setCorporate] = useState(true);
  const location = useLocation();
  const [reload, setReload] = useState(true);
  const [tagWords, setTagWords] = useState(null);
  const [searchTagWords, setSearchTagWords] = useState([]);
  const currentUser = useSelector(selectUserData);
  const [configc, setConfigc] = useState({
    loading: true,
    categories: null,
  });
  const handleReload = () => {
    setReload((prevReload) => !prevReload);
  };
  useEffect(() => {
    if (location.pathname === "/app/agreements_manager/mydocuments") {
      setTab("my documents");
      setCorporate(false);
    } else {
      setTab("corporate documents");
      setCorporate(true);
    }
  }, []);
  console.log("current user: ", currentUser);
  useEffect(() => {
    const getAllTagWords = async () => {
      try {
        const res = await apiWrapWithErrorWithData(getTagWords({ corporate }));
        setTagWords(res["uniqueTags"]);
      } catch (error) {
        console.error("Error fetching tag words:", error);
      }
    };

    getAllTagWords();
  }, [corporate]); // Rerun only when corporate changes

  useEffect(() => {
    const getdocuments = async () => {
      let filter = {};

      if (searchTagWords.length > 0) {
        filter = {
          OR: searchTagWords.map((tag) => ({
            tageWords: { contains: tag },
          })),
        };
      }
      const response = await apiWrapWithErrorWithData(
        getAllDocumentCategories({ tab: corporate, filter: filter })
      );
      console.log("response for categories: ", response);
      setConfigc({
        loading: false,
        categories: response?.data,
      });
    };
    getdocuments();
  }, [corporate, reload]);
  console.log("searched tage words: ",searchTagWords);
  return (
    <>
      <div className="m-2">
        <div>
          <h1
            style={{
              fontSize: "22px",
              fontWeight: "600",
              letterSpacing: "1px",
              fontFamily: "lexend",
              whiteSpace: "nowrap",
              paddingBottom: "13px",
              position: "relative",
              color: "black",
            }}
          >
            {tab}
          </h1>
        </div>
        <div className="flex mt-4 justify-between">
          <div className="flex">
            {tagWords && (
              <MultiSelect
              onChange={(val) => setSearchTagWords(val)}
                className="mr-4"
                data={tagWords}
              />
            )}
            <Button onClick={()=> setReload(!reload)} color="green">search</Button>
          </div>
          {currentUser.loginAs.id === 4 ?
          <Button
          style={{backgroundColor:"#46BDE1" }}
           onClick={() => setModal(true)}>add document</Button>: <div></div>
          }
        </div>
        {!configc.loading && (
          <div>
            <DocumentsTree
              handleReload={handleReload}
              documents={configc.categories}
            />
          </div>
        )}
      </div>
      <Modal
        opened={modal}
        onClose={() => setModal(false)}
        title="add document"
        size={"90%"}
      >
        <AddDocuments
          data={null}
          handleReload={handleReload}
          corporate={corporate}
          selectedFile={selectedFile}
          modal={modal}
          setModal={setModal}
        />
      </Modal>
    </>
  );
};

export default MyDocuments;
