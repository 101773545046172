import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Registering necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChartForValue = ({ rangeArray, setRange }) => {
  // Prepare the data for the Doughnut chart
  const data = {
    labels: rangeArray.map((range) => range.range),  // Extracting the range labels
    datasets: [
      {
        label: 'Agreement Count by Range',
        data: rangeArray.map((range) => range.count),  // Extracting the count for each range
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
        ],  // Background colors for the chart segments
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],  // Border colors for the chart segments
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const chartOptions1 = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, elements, chart) => {
        console.log("event getting clicked");
        const elementIndex = elements[0].index; // Index of the clicked element
        const label = chart.data.labels[elementIndex];
        setRange(label)
    },
    plugins: {
      legend: {
        position: "right",
        labels: {
          boxHeight: 10,
          boxWidth: 10,
        },
      },
    },
  };

  return (
    <div>
      
      <Doughnut data={data} options={chartOptions1} />
    </div>
  );
};

export default DoughnutChartForValue;
