/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import {
  ActionIcon,
  Badge,
  Button,
  Pagination,
  Select,
  Skeleton,
  Table,
  Text,
  TextInput,
  Anchor,
  Modal,
} from "@mantine/core";
import { BeatLoader } from "react-spinners";
import { DatePicker } from "@mantine/dates";
import { Eye, History, Search } from "tabler-icons-react";
import { formatISO } from "date-fns";
import { useDebouncedValue } from "@mantine/hooks";
import { deleteAgreementBulk } from "../../utilities/apis/agreements";
import {
  getValueForInput,
  loadingStates,
  csvDownload,
  formatDate,
} from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getAgreementsList,
  getAgreementTypesApi,
  getAgreementsCsvDownload,
} from "../../utilities/apis/agreements";
import UserAvatarView from "../../components/UserAvatarView";
import DashboardNotifications from "../DashboardNotifications";
import { agreementStatusColors } from "../../utilities/enums";
import AgreementAnalytics from "../../components/AgreementAnalytics/AgreementAnalytics";
import colors, { themeColor } from "../../utilities/design";
import AgreementGraph from "./AgreementGraph";
import Styles from "./AgreementsListingPage.module.css";
import { INDIA_STATES_UNIONS } from "./AgreementForm2";
import { selectUserData } from "../../redux/selectors";
import { useSelector } from "react-redux";
import AgreementListModal from "./AgreementListModal";
import AgreementAuditLogModal from "./AgreementAuditLogModal";

const statusOptions = [
  {
    label: "CREATED",
    value: "CREATED",
  },
  {
    label: "SENT",
    value: "SENT",
  },
  {
    label: "DECLINED",
    value: "DECLINED",
  },
  {
    label: "PARTIALLY COMPLETED",
    value: "PARTIALLYCOMPLETED",
  },
  {
    label: "COMPLETED",
    value: "COMPLETED",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
];

const estampOption = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const SignatureSideNav = () => {
  const [open, setOpen] = useState(false);
  const currentUser = useSelector(selectUserData) || {};
  const [listing, setListing] = useState({
    list: null,
    agreementsCount: null,
    loaded: false,
    sortBy: "createdAt",
    sortDirection: "desc",
    searchString: "",
    statusType: "",
    estamp: null,
    agreementType: null,
    location: null,
    startDate: null,
    endDate: null,
  });
  const [agreementTypeOptions, setAgreementTypeOptions] = useState([]);
  const [uiConfigs, setUiConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    page: 1,
  });
  const [debouncedSearchInput] = useDebouncedValue(listing.searchString, 500);
  const [agreementPayload, setAgreementPayload] = useState({});
  const [exportButtonLoading, setExportButtonLoading] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleCheckboxChange = (id, isChecked) => {
    setSelectedIds((prevSelectedIds) => {
      if (isChecked) {
        return [...prevSelectedIds, id];
      } else {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      }
    });
  };

  const deleteHandler = async () => {
    console.log("Deleting agreements with IDs:", selectedIds);
    const agreementIds = selectedIds;
    setDeleteLoading(true);
    const response = await apiWrapWithErrorWithData(
      deleteAgreementBulk({ agreementIds })
    );
    if (response?.success) {
      showNotification({
        color: "green",
        title: "Agreements",
        message: "Agreement deleted successfully.",
      });
      setOpen(false);
      navigate("/app");
    } else {
      showNotification({
        color: "red",
        title: "Agreements",
        message: "Unable to delete agreement",
      });
    }
    setDeleteLoading(false);
  };

  const fetchAgreementsList = async ({ page }) => {
    let filterOptions = {};
    if (listing.startDate && listing.endDate) {
      filterOptions["createdAt"] = {
        gte: formatISO(listing.startDate),
        lte: formatISO(listing.endDate),
      };
    } else if (listing.startDate || listing.endDate) {
      showNotification({
        title: "Agreement notification",
        message: "please select start and end date",
        color: "red",
      });
      return;
    }
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.LOADING,
    });
    const payload = {
      page: page ? page : uiConfigs.page,
      sortByOptions: {
        [listing.sortBy]: listing.sortDirection,
      },
    };
    if (listing.agreementType && listing.statusType && listing.estamp) {
      filterOptions = {
        ...filterOptions,
        typeOfAgreement: {
          equals: listing.agreementType,
        },
        status: {
          equals: listing.statusType,
        },
        agreementType: {
          equals: listing.estamp === "Yes" ? "eStamp" : "normal",
        },
      };
    } else if (listing.agreementType || listing.statusType || listing.estamp) {
      if (listing.agreementType) {
        filterOptions["typeOfAgreement"] = {
          equals: listing.agreementType,
        };
      }
      if (listing.statusType) {
        filterOptions["status"] = {
          equals: listing.statusType,
        };
      }
      if (listing.estamp) {
        filterOptions["agreementType"] = {
          equals: listing.estamp === "Yes" ? "eStamp" : "normal",
        };
      }
    }
    if (listing.location) {
      filterOptions["location"] = listing.location;
    }

    payload["filterOptions"] = filterOptions;

    setAgreementPayload(payload);
    const response = await apiWrapWithErrorWithData(
      getAgreementsList({ ...payload })
    );
    if (response?.success && response?.agreements) {
      setListing({
        ...listing,
        list: response?.agreements,
        agreementsCount: response.agreementsCount,
        loaded: true,
      });
    }
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.NO_ACTIVE_REQUEST,
    });
  };
  const data = listing?.list;

  useEffect(() => {
    getAgreementTypes();
  }, []);

  useEffect(() => {
    if (listing.agreementType || listing.statusType || listing.estamp) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else if (listing.estamp) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else if (listing.startDate && listing.endDate) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else {
      if (listing.startDate === null && listing.endDate === null) {
        fetchAgreementsList({});
      }
    }
  }, [listing.sortBy, listing.sortDirection]);

  useEffect(() => {
    fetchAgreementsList({});
  }, [uiConfigs.page]);

  const navigate = useNavigate();
  const onViewClick = (agreementId) => {
    // navigate(`/app/agreements/details/${agreementId}`);
    // const url =
    //   process.env.PUBLIC_URL +
      navigate(`/app/agreements_manager/agreements/details/${agreementId}`);
    // window.open(url, "noopener,noreferrer");
  };

  const getAgreementTypes = async () => {
    const resp = await apiWrapWithErrorWithData(getAgreementTypesApi());
    if (resp?.success && resp?.typesOfAgreement) {
      const uniqueValues = Array.from(new Set([...resp.typesOfAgreement]));
      const temp = uniqueValues.map((item) => ({ label: item, value: item }));
      setAgreementTypeOptions([...agreementTypeOptions, ...temp]);
    }
  };
  const handleCsvDownload = async () => {
    try {
      setExportButtonLoading(true);
      const response = await apiWrapWithErrorWithData(
        getAgreementsCsvDownload({ ...agreementPayload })
      );
      if (response.success) {
        if (response?.agreements.length > 0) {
          const columnHeaders = Object.keys(response.agreements[0]);
          csvDownload({
            data: response.agreements,
            headers: columnHeaders,
            name: "agreement",
          });
        } else {
          showNotification({
            title: "Agreement csv download",
            message: "No agreement available",
            color: "red",
          });
        }
      }
      setExportButtonLoading(false);
    } catch (error) {
      setExportButtonLoading(false);
      showNotification({
        title: "Agreement csv download",
        message: "Could not load agreement csv",
        color: "red",
      });
    }
  };

  const clearHandler = async () => {
    try {
      setUiConfigs({
        ...uiConfigs,
        page: 1,
        loading: loadingStates.LOADING,
      });

      const payload = {
        page: 1,
        sortByOptions: {
          [listing.sortBy]: listing.sortDirection,
        },
        filterOptions: {},
      };

      setAgreementPayload(payload);
      const response = await apiWrapWithErrorWithData(
        getAgreementsList({ ...payload })
      );

      if (response?.success && response?.agreements) {
        setListing({
          ...listing,
          searchString: "",
          agreementType: "",
          statusType: null,
          estamp: "",
          location: null,
          startDate: null,
          endDate: null,
          list: response?.agreements,
          agreementsCount: response.agreementsCount,
          loaded: true,
          estamp: null,
        });
      }

      setUiConfigs({
        ...uiConfigs,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    } catch (error) {
      console.log("Agreement list fetching error: ", error);
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [agreementId, setAgreementId] = useState(null);

  const handleSearchClick = (id) => {
    // Set the showModal state to true when the button is clicked
    setShowModal(true);
    // Pass the row id to the AgreementAuditLogModal component
    setAgreementId(id);
  };

  const handleCloseModal = () => {
    // Set the showModal state to false when the modal is closed
    setShowModal(false);
  };
  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between my-4">
          <Text>Docs Locker</Text>
        </div>
      </div>
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col">
          <div className="flex flex-row-sm">
            {/* <Text size="xs" className="mb-2" color="gray">
              Case sensitive search
            </Text> */}
            <div className="flex items-center">
              {/* <TextInput
                value={listing.searchString}
                onChange={(input) => {
                  const val = getValueForInput(input);
                  setListing((prevState) => ({
                    ...prevState,
                    searchString: val,
                  }));
                }}
                placeholder="Type to search..."
                icon={<Search size={14} />}
              /> */}
              <Select
                className="mx-2"
                placeholder="Select agreement status"
                value={listing.statusType}
                onChange={(val) => {
                  setListing((prevState) => ({
                    ...prevState,
                    statusType: val,
                  }));
                }}
                data={statusOptions}
              />
              <Select
                className="mx-2"
                placeholder="Estamp"
                value={listing.estamp}
                onChange={(val) => {
                  setListing((prevState) => ({
                    ...prevState,
                    estamp: val,
                  }));
                }}
                data={estampOption}
              />
              <Select
                className="mx-2"
                placeholder="Select agreement type"
                value={listing.agreementType}
                onChange={(val) => {
                  setListing((prevState) => ({
                    ...prevState,
                    agreementType: val,
                  }));
                }}
                data={agreementTypeOptions}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div className="flex items-center">
              <Select
                className="mx-2"
                placeholder="Select location"
                searchable={true}
                value={listing.location}
                onChange={(val) => {
                  setListing((prevState) => ({
                    ...prevState,
                    location: val,
                  }));
                }}
                data={INDIA_STATES_UNIONS}
              />
              <DatePicker
                className="mx-3"
                value={listing.startDate}
                onChange={(val) =>
                  setListing((preState) => ({ ...preState, startDate: val }))
                }
                placeholder="Created date (From)"
                minDate={
                  new Date(new Date().setMonth(new Date().getMonth() - 3))
                }
                maw={400}
                mx="auto"
              />
              <DatePicker
                className="mx-3"
                value={listing.endDate}
                onChange={(val) =>
                  setListing((preState) => ({ ...preState, endDate: val }))
                }
                placeholder="Created date (To)"
                minDate={listing.startDate}
                disabled={listing.startDate ? false : true}
              />
              <br />
            </div>
          </div>
        </div>
      </div>
      <br />
      <Button
        className="mx-2"
        color="green"
        onClick={() => fetchAgreementsList({ page: 1 })}
      >
        search
      </Button>
      <Button className="mx-2" color="red" onClick={clearHandler}>
        Clear
      </Button>

      <Button
        className="mx-2 btn btn-success btn-lg float-right"
        disabled={exportButtonLoading}
        onClick={handleCsvDownload}
        color={themeColor(colors.agreement)}
      >
        export
      </Button>
      <Modal
        overflow="inside"
        closeOnClickOutside={false}
        size="calc(30vw)"
        opened={open}
        onClose={() => setOpen(!open)}
        title="Delete Agreement"
      ></Modal>
      {showModal && (
        <Modal
          overflow="inside"
          opened
          onClose={handleCloseModal} // Call the handler on modal close
          size="calc(80vw)"
        >
          <AgreementAuditLogModal agreementId={agreementId} />
        </Modal>
      )}

      <Button
        disabled={deleteLoading || selectedIds.length === 0}
        className="ml-4"
        style={{
          backgroundColor: deleteLoading ? "#ffcccc" : "#ff4d4d",
          borderRadius: "0.5rem",
          color: "#F5F5F5",
          // width: '180px',
        }}
        onClick={deleteHandler}
      >
        {deleteLoading ? (
          <BeatLoader size={10} color={colors.rejected} />
        ) : (
          "Delete"
        )}
      </Button>
      <br />
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {uiConfigs.loading === loadingStates.LOADING && (
        <div className="flex flex-col">
          {Array.from({ length: 10 }).map((i) => (
            <Skeleton key={i} height={30} radius="md" className="my-1 w-full" />
          ))}
          <div className="flex flex-row justify-center">
            <Skeleton height={40} radius="md" className="w-40" />
          </div>
        </div>
      )}
      {listing.loaded &&
        uiConfigs.loading !== loadingStates.LOADING &&
        Boolean(data?.length) && (
          <div className="flex flex-col">
            <Table striped>
              <thead>
                <tr>
                  <th>select</th>
                  <th>Reference No.</th>
                  <th>Type</th>
                  <th>Location</th>
                  <th>Created By</th>
                  <th>Status</th>
                  <th>First Party</th>
                  <th>Second Party</th>
                  <th>Date</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .filter(
                    (v) =>
                      v.agreementData.json &&
                      v.agreementData.json.typeOfAgreement
                  )
                  .map((row) => (
                    <tr key={row.id}>
                      <td>
                        <input
                          type="checkbox"
                          id={`select-${row.id}`}
                          onChange={(e) =>
                            handleCheckboxChange(row.id, e.target.checked)
                          }
                        />
                      </td>
                      <td>{`agreement-${row.id}`}</td>
                      <td>{row.agreementData.json.typeOfAgreement}</td>
                      <td>
                        {row.agreementData?.json?.location
                          ? row.agreementData?.json?.location
                          : row.agreementData?.json?.location}
                      </td>
                      <td>
                        <UserAvatarView {...row.createdBy} />
                      </td>
                      <td>
                        <Badge
                          color={agreementStatusColors[row.status] || "orange"}
                        >
                          {row.status}
                        </Badge>
                      </td>
                      <td>
                        {row.agreementData?.json?.firstParty?.name || "-"}
                      </td>
                      <td>
                        {row.agreementData?.json?.secondParty?.name || "-"}
                      </td>
                      <td>{formatDate(row.createdAt)}</td>
                      <td>
                        <div className="flex flex-row mr-4">
                          <ActionIcon
                            onClick={() => {
                              onViewClick(row.id);
                            }}
                            color="white"
                          >
                            <Eye size={24} />
                          </ActionIcon>
                          {/* )} */}
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-row mr-4">
                          <ActionIcon
                            onClick={() => handleSearchClick(row.id)}
                            color="white"
                          >
                            <History size={24} />
                          </ActionIcon>

                          {/* )} */}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}
      {listing.loaded &&
      uiConfigs.loading !== loadingStates.LOADING &&
      !data?.length ? (
        <div
          className="flex justify-center items-center"
          style={{
            minHeight: "300px",
          }}
        >
          No agreements
        </div>
      ) : null}
      {/* </div> */}
      {listing.agreementsCount ? (
        <div className="flex flex-row justify-center my-4">
          <Pagination
            page={uiConfigs.page}
            onChange={(page) => {
              setUiConfigs({
                ...uiConfigs,
                page,
              });
            }}
            total={Math.ceil(listing.agreementsCount / 10)}
          />
        </div>
      ) : null}
      <Modal
        opened={open}
        onClose={() => setOpen(false)}
        size="calc(30vw)"
        style={{ marginTop: "12%" }}
        title="Select Agreement Type"
      >
        <div className={Styles["popup-desig"]}>
          <p
            style={{
              cursor: "pointer",
              border: "2px solid lightblue",
              padding: "8px",
              margin: "0",
              marginBottom: "8px",
            }}
            onClick={() => navigate("/app/agreements_manager/agreements/new")}
          >
            Individual Agreement
          </p>
          <p
            style={{
              cursor: "pointer",
              border: "2px solid lightblue",
              padding: "8px",
              margin: "0",
            }}
            onClick={() =>
              navigate("/app/agreements_manager/agreements/new-bulk")
            }
          >
            Bulk Agreement
          </p>
          <div style={{ textAlign: "center", paddingTop: "3%" }}>
            <button
              style={{
                cursor: "pointer",
                background: "red",
                border: "none",
                padding: "8px",
                color: "white",
                margin: "5px 0",
                borderRadius: "10%",
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SignatureSideNav;
