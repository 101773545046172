/** @format */

import React, { useEffect, useState } from "react";
import { Button } from "@mantine/core";
import flat from "flat";
import { BeatLoader } from "react-spinners";
import { loadingStates, validateEmail } from "../../utilities/utilities";
import colors from "../../utilities/design";
import { showNotification } from "@mantine/notifications";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getPartiesList,
  getWorkSpaceLists,
} from "../../utilities/apis/parties";
import AgreementParty from "./AgreementParty";
import userPartyCreateHook from "../../hooks/usePartyCreate";
import { useNavigate } from "react-router-dom";
import { getAgreementDetails, putflowJson } from "../../utilities/apis/agreements";

const AgreementForm3 = ({
  flowData,
  setFlowData,
  id,
  form,
  setUiConfigs,
  uiConfigs,
  save,
  initialState,
  actionText,
}) => {
  const agreementFormJson = flat.unflatten(form.values);
  console.log({ agreementFormJson });
  const [dropDownNames, setDropDownNames] = useState([]);
  const [dataRole, setDataRole] = useState(flowData);
  const [parties, setParties] = useState([]);
  const [addParty, setAddParty] = useState(2);
  const array = [
    "firstParty",
    "secondParty",
    "thirdParty",
    "fourthParty",
    "fifthParty",
    "sixthParty",
    "seventhParty",
    "eighthParty",
    "ninthParty",
    "tenthParty",
    "eleventhParty",
    "twelthParty",
    "thirteenthParty",
    "fourteenthParty",
    "fifteenthParty",
    "sixteenthParty",
    "seventeenthParty",
    "eighteenthParty",
    "nineteenthParty",
    "twentythParty",
  ];
  // const[name, setName]= useState("");

  userPartyCreateHook("firstParty", form, parties, initialState);
  userPartyCreateHook("secondParty", form, parties, initialState);
  userPartyCreateHook("thirdParty", form, parties, initialState);
  userPartyCreateHook("fourthParty", form, parties, initialState);
  userPartyCreateHook("fifthParty", form, parties, initialState);
  userPartyCreateHook("sixthParty", form, parties, initialState);
  userPartyCreateHook("seventhParty", form, parties, initialState);
  userPartyCreateHook("eighthParty", form, parties, initialState);
  userPartyCreateHook("ninthParty", form, parties, initialState);
  userPartyCreateHook("tenthParty", form, parties, initialState);
  userPartyCreateHook("eleventhParty", form, parties, initialState);
  userPartyCreateHook("twelthParty", form, parties, initialState);
  userPartyCreateHook("thirteenthParty", form, parties, initialState);
  userPartyCreateHook("fourteenthParty", form, parties, initialState);
  userPartyCreateHook("fifteenthParty", form, parties, initialState);
  userPartyCreateHook("sixteenthParty", form, parties, initialState);
  userPartyCreateHook("seventeenthParty", form, parties, initialState);
  userPartyCreateHook("eighteenthParty", form, parties, initialState);
  userPartyCreateHook("nineteenthParty", form, parties, initialState);
  userPartyCreateHook("twentythParty", form, parties, initialState);

  const partiesApi = async (name) => {
    const { data: res } = await apiWrapWithErrorWithData(
      getPartiesList({ name })
    );
    setParties(res);
    const names = res.map(({ name: val }) => val);
    console.log("response for parties: ", res);
    setDropDownNames(names);
  };
  console.log(dropDownNames);
  useEffect(() => {
    for (let i = 20; i >= 1; i--) {
      const partyName = array[i - 1];
      if (agreementFormJson[partyName] && Object.keys(agreementFormJson[partyName]).length) {
        setAddParty(i);
        break; // Exit loop once the largest party number with data is found
      }
    }
    partiesApi();
  }, []);
  // console.log(addParty);
  const validate = () => {
    const keys = {};

    const validateParty = (party) => {
      if (!party) return;

      if (party.type === "Entity" && (!party.name || party.name.length < 3)) {
        keys[`${party}.name`] = "please enter 3 or more characters.";
      }

      if (
        party.type === "Entity" &&
        (!party.signatory || party.signatory.length < 3)
      ) {
        keys[`${party}.signatory`] = "please enter 3 or more characters.";
      }

      if (party.type === "Entity" && !validateEmail(party.email)) {
        keys[`${party}.email`] = "please enter correct email.";
      }

      if (
        party.type === "Entity" &&
        (!party.entityType || party.entityType.length < 3)
      ) {
        keys[`${party}.entityType`] = "please fill Entity Type.";
      }

      if (
        party.type === "Individual" &&
        (!party.indi_name || party.indi_name.length < 3)
      ) {
        keys[`${party}.indi_name`] = "please enter 3 or more characters.";
      }

      if (party.type === "Individual" && !validateEmail(party.indi_email)) {
        keys[`${party}.indi_email`] = "please enter correct email.";
      }
      if(! party.brandName ){
        keys[`${party}.brandName`]="please enter brand name.";
      }
    };

    // let parties = [agreementFormJson.firstParty, agreementFormJson.secondParty];
    const parties = [
      agreementFormJson.firstParty,
      agreementFormJson.secondParty,
    ];

    for (let i = 3; i <= Math.min(addParty, 20); i++) {
      const partyName = array[i - 1]; // Get the corresponding party name from the array
      parties.push(agreementFormJson[partyName]);
    }
    // parties =  [...new Set(parties)];
    parties.forEach(validateParty);

    if (Object.keys(keys).length !== 0) {
      form.setErrors(keys);
      showNotification({
        color: "red",
        title: "Agreement Form",
        message: "Please check all fields are filled properly.",
      });
    }

    return Object.keys(keys).length === 0;
  };
  useEffect(() => {
    const fetchData = async () => {
      console.log("bhai ye trigger hua h ?");
      const agreementId = id;
      const  data  = await apiWrapWithErrorWithData(getAgreementDetails({ agreementId }));
      const fetchedDataRole = data.agreement?.flowJson;
      console.log("fetch data role: ", fetchedDataRole);
      if (fetchedDataRole) {
        setDataRole(fetchedDataRole);
        console.log("yha aya to h ")
      } 
      
    };

    fetchData();
  }, []);
  const savePrev = async () => {
    setUiConfigs({
      ...uiConfigs,
      currentStep: 1,
    });
  };
  console.log("data role: ", dataRole);
  const navigate = useNavigate();
  const saveNext = async () => {
    if (validate()) {
      if (actionText === "sent_to_doc_locker") {
        setFlowData(dataRole);
        // await apiWrapWithErrorWithData(putflowJson(id, dataRole));
        await save({ ...form.values, formCompleted: true });
        navigate("/app/agreements_manager/agreements/details/" + id);
      } else {
        setFlowData(dataRole)
        await save({ ...form.values, formCompleted: true });
        setUiConfigs({
          ...uiConfigs,
          currentStep: 3,
        });
      }
    }
  };

  
  return (
    <div className="w-full flex flex-col my-4">
      <div className="flex flex-col justify-between my-2">
        {array.slice(0, addParty).map((partyName, index) => (
          <AgreementParty
            key={partyName}
            agreementFormJson={agreementFormJson}
            dropDownNames={dropDownNames}
            form={form}
            partyName={partyName}
            setDropDownNames={setDropDownNames}
            dataRole={dataRole}
            setDataRole={setDataRole}
          />
        ))}
      </div>
      <div className="flex justify-between">
        {addParty > 2 ? (
          <Button onClick={() => setAddParty((pre) => pre - 1)}>
            delete party
          </Button>
        ) : (
          <div></div>
        )}
        {addParty < 20 && (
          <Button style={{
            backgroundColor: "#46BDE1",
          }} onClick={() => setAddParty((pre) => pre + 1)}>
            add party
          </Button>
        )}
      </div>
      <div className="flex flex-row justify-between">
        {uiConfigs.loading === loadingStates.LOADING ? (
          <BeatLoader color={colors.primary} size={10} />
        ) : (
          <>
            <Button
              className="max-w-xs mt-20"
              onClick={savePrev}
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              previous
            </Button>
            <Button
              className="max-w-xs mt-20"
              onClick={saveNext}
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              {actionText === "sent_to_doc_locker" ? "update" : "next"}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AgreementForm3;
