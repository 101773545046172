// Source: https://ui.mantine.dev/component/navbar-nested

import React, { useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  createStyles,
  Anchor,
} from "@mantine/core";
import { ChevronLeft, ChevronRight } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  link: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    paddingLeft: 31,
    marginLeft: 30,
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    borderLeft: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  chevron: {
    transition: "transform 200ms ease",
  },
}));

export function LinksGroup({
  icon: Icon,
  label,
  initiallyOpened,
  links,
  link,
  hasNumber,
}) {
  const { classes, theme } = useStyles();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const ChevronIcon = theme.dir === "ltr" ? ChevronRight : ChevronLeft;
  const navigate = useNavigate();
  const items = (hasLinks ? links : []).map((itemLink) => (
    <Text
      component="a"
      className={classes.link}
      href={itemLink.link}
      key={itemLink.label}
      onClick={(event) => {
        event.preventDefault();
        navigate(itemLink.link);
        window.location.reload();
      }}
    >
      {itemLink.label}
    </Text>
  ));

  return (
    <>
      <UnstyledButton
        onClick={() => {
          if (link && link.length) {
            window.location.href = link;
          }
          setOpened((o) => !o);
        }}
        className={classes.control}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Anchor className="my-1" size={30}>
              <Icon color="#46BDE1" size={22} />
            </Anchor>
            <Box className="flex" ml="md">
              {label}{" "}
              {hasNumber && (
                <div
                  className="justify-center p-auto ml-4"
                  style={{
                    backgroundColor: "#46BDE1",
                    width: "22px",
                    height: "22px",
                    color: "white",
                    textAlign: "center",
                    
                    borderRadius: "50%",
                  }}
                >
                  {hasNumber}
                </div>
              )}
            </Box>
          </Box>
          {hasLinks && (
            <ChevronIcon
              className={classes.chevron}
              size={14}
              //
              style={{
                transform: opened
                  ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
                  : "none",
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}
