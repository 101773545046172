import React from "react";
import { formatDate } from "../../utilities/utilities";

const EmailList = ({ email, subject, time }) => {
  return (
    <div className="mb-1 pr-3">
      <div className="flex justify-between my-1">
        <p className="text-base font-semibold">{email}</p>{" "}
        <p>{formatDate(time)}</p>
      </div>

      <p className="text-base font-normal">
        {subject.includes(":") ? subject.split(":")[1] : subject}
      </p>
    </div>
  );
};

export default EmailList;
