import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Grid, Skeleton } from "@mantine/core";
import { loadingStates } from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getAgreementsInReviewList } from "../../utilities/apis/agreements";
import {
  customChartData,
  customChartDataForLocation,
} from "../../utilities/utilities";
import DoughnutChart from "../doughnutChart/doughnutChart";
import DoughnutChartForValue from "../doughnutChart/doughnutChartForValue";

ChartJS.register(ArcElement, Tooltip, Legend);

const AgreementAnalytics1 = ({filters, setFilter, configs, setRange}) => {
   const chartOptions1 = {
     responsive: true,
     maintainAspectRatio: false,
     
     plugins: {
       legend: {
         position: "right",
         labels: {
           boxHeight: 10,
           boxWidth: 10,
         },
       },
     },
   };
  

  return (
    <div className="w-full">
      <Grid>
        {configs.loading === loadingStates.LOADING && (
          <>
            <Grid.Col span={4}>
              <Skeleton style={{ height: "310px" }} className="mx-8" visible />
            </Grid.Col>
            <Grid.Col span={4}>
              <Skeleton style={{ height: "310px" }} className="mx-8" visible />
            </Grid.Col>
            <Grid.Col span={4}>
              <Skeleton style={{ height: "310px" }} className="mx-8" visible />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton style={{ height: "310px" }} className="mx-8" visible />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton style={{ height: "310px" }} className="mx-8" visible />
            </Grid.Col>
          </>
        )}
        {configs.loading !== loadingStates.LOADING &&
          configs.data?.agreementTypeWise && (
            <>
              <Grid.Col span={6}>
                <div
                  className="border-2 border-none rounded-lg p-4"
                  style={{
                    boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1) ",
                  }}
                >
                  <div>category</div>
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      padding: "16px",
                      position: "relative",
                    }}
                  >
                    <DoughnutChart
                      chartOptions={chartOptions1}
                      method={customChartData("typeOfAgreement")}
                      data={configs?.data?.agreementTypeWise}
                      filters={filters}
                      type={"typeOfAgreement"}
                      setFilters={setFilter}
                    />
                  </div>
                </div>
              </Grid.Col>
              <Grid.Col span={6}>
                <div
                  className="border-2 border-none rounded-lg p-4"
                  style={{
                    boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1) ",
                  }}
                >
                  <div>business unit</div>
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      padding: "16px",
                      position: "relative",
                    }}
                  >
                    <DoughnutChart
                      chartOptions={chartOptions1}
                      method={customChartDataForLocation("location")}
                      data={configs?.data?.agreementsLocationWise }
                      filters={filters}
                      setFilters={setFilter}
                      type={"mapBusinessunit"}
                    />
                  </div>
                </div>
              </Grid.Col>
              {/* <Grid.Col span={6}>
              <div
                  className="border-2 border-none rounded-lg p-4"
                  style={{
                    boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1) ",
                  }}
                >
                  <div>agreement value</div>
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      padding: "16px",
                      position: "relative",
                    }}
                  >
                    <DoughnutChartForValue
                    rangeArray={configs?.data?.rangeArray}
                    setRange={setRange}
                      // chartOptions={chartOptions1}
                      // method={customChartDataForLocation("location")}
                      // data={configs?.data?.agreementsLocationWise }
                      // filters={filters}
                      // setFilters={setFilter}
                      // type={"mapBusinessunit"}
                    />
                  </div>
                </div>
              </Grid.Col> */}
            </>
          )}
      </Grid>
    </div>
  );
};

export default AgreementAnalytics1;
