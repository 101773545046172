import React from 'react';
import styles from './AgreementDetail.module.css';
import { formatDate } from '../../utilities/utilities';

function ContractDetail({
  typeOfAgreement, termOfContractInDays, dateOfContract, endDate, location
}) {
  return (
    <div>
      {/* <div className={`${styles.title} mt-4`}>contract details</div> */}
      <div className="grid grid-cols-4 gap-y-9 gap-x-4 mt-3 mb-9">
        <div>
          <div style={{color:  "rgb(70, 189, 225)"}} className={styles.label}>type</div>
          <div className={styles.text}>{typeOfAgreement}</div>
        </div>
        <div>
          <div style={{color:  "rgb(70, 189, 225)"}} className={styles.label}>start date</div>
          <div className={styles.text}>{formatDate(dateOfContract)}</div>
        </div>
        <div>
          <div style={{color:  "rgb(70, 189, 225)"}} className={styles.label}> term (in days)</div>
          <div className={styles.text}>{termOfContractInDays}</div>
        </div>
        <div>
          <div style={{color:  "rgb(70, 189, 225)"}} className={styles.label}> end date</div>
          <div className={styles.text}>{formatDate(endDate)}</div>
        </div>
        <div>
          <div style={{color:  "rgb(70, 189, 225)"}} className={styles.label}>business unit</div>
          <div className={styles.text}>{location}</div>
        </div>
      </div>
    </div>
  );
}

export default ContractDetail;
