import { Skeleton } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getTotalAgreementsList } from "../../utilities/apis/agreements";
import { loadingStates } from "../../utilities/utilities";

function AgreementGraph1({configs}) {
  const years = Array.from({ length: 8 }, (_, i) => 2022 + i);
  const dataCounts = years.map(year => {
    const yearData = configs?.data?.agreementByYear?.find(el => el.year === (year-2000));
    return yearData ? yearData["_count"].id : 0; 
  });
  return (
    <div style={{ width: "50%" }} className="pl-2">
      <div
        className="border-solid border-gray-100 border-2 rounded-lg mt-4"
        style={{
          border: "none",
          boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1)",
          width: "100%",
          padding: "2%",
        }}
      >
        <div className="h-8"></div>
        <div className="mt-2 ml-4">yearly stats</div>
        {configs.loading === loadingStates.LOADING && (
          <Skeleton style={{ height: "200px" }} className="mt-4 mx-8" visible />
        )}
        {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
          configs.data.agreementByYear && (
            <div
              className="w-full"
              style={{
                height: "200px",
                width: "100%",
              }}
            >
              <Bar
                data={{
                  labels: years.map(year => `${year}`),
                  datasets: [
                    {
                      label: "agreements",
                      backgroundColor: 'rgb(70, 189, 225)',
                      barThickness: 30,
                      barPercentage: 0.5,
                      borderRadius: 10,
                      data: dataCounts,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "top",
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: "agreements",
                      },
                      grid: {
                        display: false, // Remove grid lines
                      },
                      ticks: {
                        display: false, 
                      }
                    },
                    x: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        // text: 'No. of Agreement Completed Month wise',
                        text: "year",
                      },
                      grid: {
                        display: false, // Remove grid lines
                      },
                    },
                  },
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default AgreementGraph1;
