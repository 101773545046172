/** @format */

import React, { useState, useEffect } from "react";
import {
  Text,
  Textarea,
  Select,
  TextInput,
  SegmentedControl,
} from "@mantine/core";
import { camelCaseToTitle } from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  businessUnitDetails,
  getAgreementDetails,
  getOrgDetails,
  getuserMail,
} from "../../utilities/apis/agreements";
import flat from "flat";
import { getUserRolesUsingRoleId } from "../../utilities/apis/serviceProvider";

const AgreementParty = ({
  agreementFormJson,
  partyName,
  dropDownNames,
  setDropDownNames,
  form,
  setName,
  dataRole,
  setDataRole
}) => {
  if (!agreementFormJson[partyName].type) {
    agreementFormJson[partyName].type = "Entity";
  }
  const [budetails, setBudetails] = useState(null);
  const [userRolesIdData, setUserRolesIdData] = useState({
    userRoles: {
      SigningAuthority: [],
    },
  });
  
  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        const roles = {
          SigningAuthority: (await getUserRolesUsingRoleId(7)).data.userList,
        };

        setUserRolesIdData((prevData) => ({
          ...prevData,
          userRoles: roles,
        }));
      } catch (error) {
        console.error("Error fetching user roles:", error);
      } finally {
        console.log("final")
      }
    };

    fetchUserRoles();
  }, []);
  

  const bu = form.values["businessUnit"];
  console.log("buid: ", bu);
  const budetailsfetch = async () => {
    const response = await apiWrapWithErrorWithData(
      businessUnitDetails({ businessUnitId: bu })
    );
    // console.log(response);
    if (response.success) {
      setBudetails(response.details);
    } else {
      console.log("error accured in fetching bu details");
    }
  };

  useEffect(() => {
    budetailsfetch();
  }, [bu]);
  // console.log("budetails: ", budetails);
  console.log("form: ", form.values);
  const type = agreementFormJson[partyName].type || "Entity";
  // console.log("type of sanjf: ", type);

  const [phone, setPhone] = useState("");

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Allow only up to 10 digits
    if (value.length <= 10 && /^\d*$/.test(value)) {
      setPhone(value);
      // Update the form state only for a complete 10-digit number
      if (value.length === 10) {
        form.setValue(`${partyName}.phone`, value);
      }
    }
  };
  const [orgDetails, setOrgDetails] = useState({
    address: "",
    name: "",
    entityType: "Company",
  });
  useEffect(() => {
    const getOrganizationDetails = async () => {
      const resp = await apiWrapWithErrorWithData(getOrgDetails());
      if (resp.success) {
        setOrgDetails({
          address: resp.organizationDetails.orgData.regAddress,
          entityType: resp.organizationDetails.orgData.entityType,
          name: resp.organizationDetails.orgName,
        });
      }
    };
    getOrganizationDetails();
  }, []);
  useEffect(() => {
    if (partyName === "firstParty" && budetails && orgDetails) {
      form.setValues((prevValues) => {
        const currentValues = flat.unflatten(prevValues);

        // Check if firstParty.name exists
        if (!currentValues.firstParty?.name) {
          return flat({
            ...currentValues,
            firstParty: {
              address: orgDetails.address,
              corporateOfficeAddress: budetails.copAddress,
              email: budetails.signEmail,
              entityType: orgDetails.entityType,
              name: orgDetails.name,
              representative: budetails.poc,
              signatory: budetails.signName,
              type: "Entity",
            },
          });
        }

        return prevValues;
      });
    }
  }, [budetails, orgDetails, partyName]);
  const getMail = async({id})=>{
    const resp = await apiWrapWithErrorWithData(getuserMail({id}))
    if(resp.success){
      return resp.email;
    }else{
      return null;
    }
  }
  // Debugging: Log the current phone state and form state value
  // console.log("Local phone state:", phone);
  // console.log("Form state for phone:", form.values[`${partyName}.phone`]);
  // const [type, setType] = useState("Entity");

  return (
    <div className="flex flex-col">
      <br></br>
      <div className="flex justify-between" style={{ marginTop: "10px" }}>
        <Text style={{textTransform: "lowercase",}} color="#46BDE1" >{camelCaseToTitle(partyName)}</Text>
        <SegmentedControl
          {...form.getInputProps(`${partyName}.type`)}
          color="cyan"
          data={["Entity", "Individual"]}
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        {type === "Entity" && (
          <>
            <Select
              required
              className="inputCustom "
              label="entity type"
              style={{ width: "100%", marginLeft: "0px", lineHeight: "1.55" }}
              data={[
                { value: "Company", label: "Company" },
                { value: "LLP", label: "LLP" },
                { value: "Partnership Firm", label: "Partnership Firm" },
                { value: "Sole Proprietary", label: "Sole Proprietary" },
              ]}
              {...form.getInputProps(`${partyName}.entityType`)}
            />
            {partyName !== "firstParty" ? (
              <Select
                required
                searchable
                creatable
                className="inputCustom "
                label="registered name"
                // onChange={(e)=> setName(e.target.value)}
                placeholder="enter registered name"
                data={[...dropDownNames]}
                getCreateLabel={(query) => `+ add ${query}`}
                onCreate={(query) => {
                  setDropDownNames([...dropDownNames, query]);
                }}
                {...form.getInputProps(`${partyName}.name`)}
                style={{
                  width: "100%",
                  marginLeft: "0px",
                  margin: "0px",
                  padding: "0px",
                  lineHeight: "1.55",
                }}
              />
            ) : (
              <TextInput
                required
                label="registered name"
                placeholder="enter registered name"
                {...form.getInputProps(`${partyName}.name`)}
              />
            )}
            <TextInput
            required
            label="brand name"
            placeholder="enter brand name" 
            {...form.getInputProps(`${partyName}.brandName`)}
            />
            {partyName === "firstParty" ? (
              <>
                <Select
                  key="SigningAuthority"
                  label="signing authority"
                  placeholder="signing authority"
                  value={dataRole["7"][0]?.userId || null}
                  onChange={async(selectedUser) => {
                    setDataRole({
                      ...dataRole,
                      ["7"]: [
                        {
                          userId: selectedUser,
                          isComplete: false,
                        },
                      ],
                    });
                    const selectedUserLabel = userRolesIdData.userRoles.SigningAuthority.find(
                      (user) => user.id === selectedUser
                    )?.name;
                    form.setFieldValue(`${partyName}.signatory`, selectedUserLabel)
                    form.setFieldValue(`${partyName}.email`, await getMail({id: selectedUser}))
                  }}
                  data={userRolesIdData.userRoles.SigningAuthority.map(
                    (user) => ({
                      value: user.id,
                      label: user.name,
                    })
                  )}
                  required
                />
              </>
            ) : (
              <>
                <TextInput
                  required
                  label="authorised signatory name"
                  placeholder="enter authorised signatory name"
                  {...form.getInputProps(`${partyName}.signatory`)}
                />
                <TextInput
                  required
                  label="authorised signatory email"
                  placeholder="enter authorised signatory email"
                  {...form.getInputProps(`${partyName}.email`)}
                />
              </>
            )}
            <TextInput
              label="business POC"
              placeholder="enter business POC"
              {...form.getInputProps(`${partyName}.representative`)}
            />
            <TextInput
              label="business POC email"
              placeholder="enter business email"
              {...form.getInputProps(`${partyName}.pocemail`)}
            />
            {/* {partyName === "firstParty" && <div></div>} */}
            <Textarea
              label="registered address"
              placeholder="enter registered address"
              {...form.getInputProps(`${partyName}.address`)}
            />
            <Textarea
              label="corporate address"
              placeholder="enter corporate address"
              {...form.getInputProps(`${partyName}.corporateAddress`)}
            />
          </>
        )}
        {type === "Individual" && (
          <>
            <TextInput
              required
              label="individual Name"
              placeholder="enter individual name"
              {...form.getInputProps(`${partyName}.indi_name`)}
            />
            <TextInput
              label="father's name"
              placeholder="enter father's name"
              {...form.getInputProps(`${partyName}.indi_fatherName`)}
            />
            <Textarea
              label="residence address"
              placeholder="enter residence address"
              {...form.getInputProps(`${partyName}.indi_address`)}
            />
            <TextInput
              label="PAN no."
              placeholder="enter PAN no."
              {...form.getInputProps(`${partyName}.indi_pan`)}
            />
            <TextInput
              required
              label="email"
              placeholder="enter email id"
              {...form.getInputProps(`${partyName}.indi_email`)}
            />
            <TextInput
              label="phone no."
              placeholder="enter 10 digit phone no."
              value={phone}
              onChange={handlePhoneChange}
              {...form.getInputProps(`${partyName}.indi_phone`)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AgreementParty;
