import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Anchor,
  Button,
  Modal,
  Progress,
  Text,
} from "@mantine/core";
import flat from "flat";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { loadingStates, validateEmail } from "../../utilities/utilities";
import colors from "../../utilities/design";
import { showNotification } from "@mantine/notifications";
import Editor from "../../components/Editor";
import TemplatesList from "../../components/TemplateManager/TemplatesList";
import {
  getTemplateEditorField,
  templatesDetailsApi,
} from "../../utilities/apis/templates";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { selectUserData } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  createEditorField,
  getEditorField,
  uploadFromLocal,
} from "../../utilities/apis/agreements";
import TaskNewForm from "../../components/CaseNewUI/TaskNewForm";
import { selectAgreementWorkFlow } from "../../redux/selectors";
import { agreementTranSaveApi } from "../../utilities/apis/agreements";
import { IconArrowLeft } from "@tabler/icons-react";
import { Trash } from "tabler-icons-react";

const AgreementForm4 = ({ id, form, setUiConfigs, uiConfigs, save }) => {
  const agreementFormJson = flat.unflatten(form.values);
  const slctAgreementWorkFlow = useSelector(selectAgreementWorkFlow) || [];
  console.log("Form values after user involved:", form.values);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUserData) || {};

  const [modal, setModal] = useState(false);
  const [screen, setScreen] = useState(1);
  const [configs, setConfigs] = useState({
    templateView: false,
    editorKey: "key-1",
    templateId: null,
    editorContent: agreementFormJson.contractQuillJsDelta || {
      ops: [{ insert: "Agreement content goes here..." }],
    },
    templateField: "",
  });
  const [tasksConfig, setTasksConfig] = useState({
    reviweFormVisible: false,
    taskFormVisible: false,
    loading: loadingStates.NO_ACTIVE_REQUEST,
    tasks: [],
    page: 1,
    tasksCount: null,
    loadTask: null,
    loadRemark: null,
    completionFlagBtnLoading: false,
  });
  const [locked, setLocked] = useState(true);
  const [show, setShow] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  console.log("selected file:", selectedFile);
  const getTemplateField = async (templateId) => {
    const formData = {
      templateId,
    };
    console.log("first function called");
    // const res = await apiWrapWithErrorWithData(templatesDetailsApi({templateId: templateId}));

    // console.log("response for template details: ", res);
    // setConfigs({...configs, editorContent: res.template.quillDelta});
    const response = await apiWrapWithErrorWithData(
      getTemplateEditorField(formData)
    );
    if (response.success) {
      setConfigs((prevState) => ({
        ...prevState,
        templateField: response?.getFieldName?.fieldName,
      }));
      console.log("template fields: ", response?.getFieldName?.fieldName);
      saveAgreementInputField(response?.getFieldName?.fieldName);
    } else {
      // showNotification({
      //   color: "red",
      //   title: "Editor Field",
      //   message: "Something went wrong.",
      // });
    }
  };

  const saveAgreementInputField = async (inputField) => {
    console.log("input fields for agrement: ", inputField);
    const formData = {
      fieldName: [...new Set(inputField)],
      agreementId: id,
    };
    const response = await apiWrapWithErrorWithData(
      createEditorField(formData)
    );

    if (response?.success) {
      console.log(response.success);
    } else {
      // showNotification({
      //   message: "Error in saving Editor Field.",
      //   title: "Editor Field",
      //   color: "red",
      // });
    }
  };

  const savePrev = async () => {
    setUiConfigs({
      ...uiConfigs,
      currentStep: 3,
    });
  };

  // const localSave = async () => {
  //   if (selectedFile) {
  //     await save({
  //       ...form.values,
  //       contractQuillJsDelta: configs.editorContent,
  //       //contractQuillJsDelta:{
  //       //  ops: [{ insert: cntt }],
  //       //},
  //       formCompleted: true,
  //     });
  //     const formData = new FormData();
  //     const file = selectedFile;
  //     formData.append("file", file);
  //     try {
  //       const response = await uploadFromLocal({
  //         agreementId: id,
  //         formData: formData,
  //       });
  //       console.log("response: ", response);
  //       showNotification({
  //         title: "upload from local",
  //         message: "content uploaded",
  //         color: "green",
  //       });
  //       navigate("/app/agreements_manager/agreements/details/" + id);
  //     } catch (error) {
  //       console.log("error: ", error);
  //       showNotification({
  //         title: "upload from local",
  //         message: "couldnot upload ",
  //         color: "red",
  //       });
  //     }
  //   } else {
  //     showNotification({
  //       title: "error",
  //       message: "please select a file before finish",
  //       color: "red",
  //     });
  //   }
  // };

  const saveNext = async () => {
    // let cntt = document.querySelector(".ql-editor").innerHTML; //.replace(/"/g, '&#34;')
    setLoading(true);
    if (selectedFile) {
      await save({
        ...form.values,
        contractQuillJsDelta: configs.editorContent,
        //contractQuillJsDelta:{
        //  ops: [{ insert: cntt }],
        //},
        formCompleted: true,
      });
      const formData = new FormData();
      const file = selectedFile;
      formData.append("file", file);
      try {
        const response = await uploadFromLocal({
          agreementId: id,
          formData: formData,
        });
        console.log("response: ", response);
        showNotification({
          title: "upload from local",
          message: "content uploaded",
          color: "green",
        });
        setLoading(false);
        navigate("/app/agreements_manager/agreements/details/" + id);
      } catch (error) {
        console.log("error: ", error);
        setLoading(false);
        showNotification({
          title: "upload from local",
          message: "couldnot upload ",
          color: "red",
        });
      }
    } else {
      setLoading(true);
      await save({
        ...form.values,
        contractQuillJsDelta: configs.editorContent,
        //contractQuillJsDelta:{
        //  ops: [{ insert: cntt }],
        //},
        formCompleted: true,
      });
      setLoading(false);
      navigate("/app/agreements_manager/agreements/details/" + id);
    }
    

  };

  useEffect(() => {
    if (configs.templateId) {
      getTemplateField(configs.templateId);
    }
  }, [configs.templateId]);
  const handleSaveDisplay = () => {
    var crntStatusCheck2 = false;

    slctAgreementWorkFlow?.map((ele) => {
      if (
        ele.crntStatus === "draft" &&
        (currentUser.loginAs.id === 3 || currentUser.loginAs.id === 4)
      ) {
        //  console.log("inside");
        //  console.log(currentUser.loginAs.id);
        //  console.log(ele.crntStatus);
        crntStatusCheck2 = true;
      }
    });
    return crntStatusCheck2;
  };
  const handleSendForReviewDisplay = () => {
    var crntStatusCheck2 = false;

    slctAgreementWorkFlow?.map((ele) => {
      if (
        ele.crntStatus === "send_to_reviewer" &&
        (currentUser.loginAs.id === 3 || currentUser.loginAs.id === 4)
      ) {
        // console.log("inside");
        // console.log(currentUser.loginAs.id);
        // console.log(ele.crntStatus);
        crntStatusCheck2 = true;
      }
    });
    return crntStatusCheck2;
  };
  const handleSendForApprovalDisplay = () => {
    var crntStatusCheck2 = false;

    slctAgreementWorkFlow?.map((ele) => {
      if (
        ele.crntStatus === "send_to_approver" &&
        currentUser.loginAs.id === 4
      ) {
        // console.log("inside");
        // console.log(currentUser.loginAs.id);
        // console.log(ele.crntStatus);
        crntStatusCheck2 = true;
      }
    });
    return crntStatusCheck2;
  };
  const handleSendForSignatureDisplay = () => {
    var crntStatusCheck2 = false;

    slctAgreementWorkFlow?.map((ele) => {
      if (
        ele.crntStatus === "send_to_signauth" &&
        currentUser.loginAs.id === 5
      ) {
        //  console.log("inside");
        //  console.log(currentUser.loginAs.id);
        //  console.log(ele.crntStatus);
        //  flowId = ele.id
        crntStatusCheck2 = true;
      }
    });
    return crntStatusCheck2;
  };

  const handleButtonClickSave = async () => {
    console.log("here");
    var flow_id = null;

    slctAgreementWorkFlow?.map((ele) => {
      if (
        ele.crntStatus === "draft" &&
        (currentUser.loginAs.id === 3 || currentUser.loginAs.id === 4)
      ) {
        flow_id = ele.id;
      }
    });

    const agreementTran = { flowId: flow_id, agreementId: id };

    console.log(agreementTran);

    const { data } = await agreementTranSaveApi(agreementTran);
    console.log("Response", data);
    if (data?.success) {
      showNotification({
        title: "Success",
        message: "Agreement data saved successfully",
        color: "green",
      });
    } else {
      showNotification({
        title: "Error",
        message: "Failed to save agreement data",
        color: "red",
      });
    }
  };
  useEffect(() => {
    if (selectedFile !== null) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [selectedFile]);

  return (
    <>
      {/* {screen === 0 && (
        <>
          <div className="flex justify-center">
            <div className=" mt-10">
              <Button onClick={() => setScreen(2)} className=" my-4 block">
                upload from local
              </Button>
              <Button className="my-4 block" onClick={() => setScreen(1)}>
                choose a template
              </Button>
            </div>
          </div>
        </>
      )} */}

      <div className="w-full max-w-10/12 my-4 px-8">
        <div>
          <div className="w-full mt-4">
            {selectedFile === null ? (
              <div className="flex items-center justify-center  m-4">
                <label
                  for="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-34 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      ariaHidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span>click to upload</span> or drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      only docx file is supported
                    </p>
                  </div>
                  <input
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    id="dropzone-file"
                    type="file"
                    accept=".docx"
                    className="hidden"
                  />
                </label>
              </div>
            ) : (
              <div className="flex items-center justify-center w-full mx-6">
                <div className="flex flex-row w-full mt-4">
                  <img
                    //   className="m-auto"
                    src="/assets/images/downloadFileLogo.svg"
                    alt="file upload"
                  />

                  <div className="flex flex-col pl-3 w-10/12 ">
                    <div className="flex flex-row justify-between">
                      <div className="text-xl">{selectedFile.name}</div>
                    </div>
                    <div className="mt-3">
                      <Progress color={"teal"} size="sm" value={100} />
                    </div>
                  </div>

                  <div className="flex items-center">
                    <Anchor
                      // onClick={downloadFile(index)}
                      className="mx-3 "
                      color="#46BDE1"
                      // disabled={file.downloading === loadingStates.LOADING}
                    >
                      <Text>{selectedFile.fileName}</Text>
                    </Anchor>
                    {/* <Button
                    className="mx-3 w-60"
                    color="#46BDE1"
                    variant="outline"
                    onClick={viewDocuemnt}
                  >
                    Open
                  </Button> */}
                    <ActionIcon color="red">
                      <Trash
                        onClick={() => setSelectedFile(null)}
                        // onClick={deleteFile(index)}
                      />
                    </ActionIcon>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div
            style={{ width: "200px", border: "3px solid #46BDE1" }}
            className="flex justify-center w-auto py-2 rounded"
          >
            <div>
              <label
                htmlFor="file-upload"
                className="text-sm font-bold opacity-90 text-black custom-file-upload"
              >
                upload from local
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".docx"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                style={{ display: "none" }}
              />
            </div>
          </div> */}
      </div>
      {show && 
        <>
        <div className="flex w-full items-center px-8">
          <hr style={{borderTop: "3px dotted black", width: "45%"}} />
          <Text
            className="text-xl my-4 font-bold text-center"
            style={{ color: "#46BDE1", width: "10%" }}
          >
            or
          </Text>
          <hr style={{borderTop: "3px dotted black", width: "45%"}} />
        </div>
          

          {configs.templateView && (
            <Modal
              overflow="inside"
              opened
              onClose={() => {
                setConfigs((prevState) => ({
                  ...prevState,
                  templateView: false,
                }));
              }}
              size="calc(80vw)"
            >
              <TemplatesList
                showNewTemplateBtn={false}
                templateType="Agreements"
                useTemplateFunc={(template) => {
                  console.log("data of template", {
                    template,
                  });

                  setConfigs((prevState) => ({
                    ...prevState,
                    templateView: false,
                    editorContent: template.quillDelta,
                    templateId: template.id,
                    editorKey: "key-" + Math.random(),
                  }));
                }}
              />
            </Modal>
          )}
          {tasksConfig.taskFormVisible && (
            <Modal
              closeOnClickOutside={false}
              overflow="inside"
              opened
              onClose={() => {
                setTasksConfig({
                  ...tasksConfig,
                  taskFormVisible: false,
                });
              }}
              size="calc(80vw)"
            >
              <TaskNewForm
                onModalExit={() => {
                  setTasksConfig({
                    ...tasksConfig,
                    taskFormVisible: false,
                  });
                }}
              />
            </Modal>
          )}
      <div className="w-full flex flex-col ">
        <div className="flex justify-end">
          <Button
            onClick={() => {
              setConfigs((prevState) => ({
                ...prevState,
                templateView: true,
              }));
            }}
            style={{
              backgroundColor: "#46BDE1",
            }}
          >
            choose from template
          </Button>
        </div>

        <div className="flex flex-col justify-center mt-4 mx-auto">
          <Editor
            // setLocked={setLocked}
            // locked={locked}
            key={configs.editorKey}
            content={configs.editorContent}
            agreementId={id}
            templateId={configs.templateId}
            onContentChange={(content) => {
              setConfigs((prevState) => ({
                ...prevState,
                editorContent: content,
              }));
            }}
          />
          <div className="flex flex-row justify-start gap-4 mx-auto">
            {/* {currentUser.loginAs.id === 3 ||
                     currentUser.loginAs.id === 4 ||
                     currentUser.loginAs.id === 2 ? ( */}
            <>
              {/* <Button
                className="max-w-xs mt-12"
                onClick={handleButtonClickSave}
                style={{
                  backgroundColor: "#F18D05",
                  display: handleSaveDisplay() ? "block" : "none",
                }}
              >
                Save
              </Button> */}
              {/* <Button
                className="max-w-xs mt-12"
                // onClick={() => handleButtonClick("Send for Review")}
                onClick={handleButtonClickSendForReview}
                style={{
                  backgroundColor: "#F18D05", // Different color
                  display: handleSendForReviewDisplay() ? "block" : "none",
                }}
              >
                Submit
              </Button> */}
            </>

            {/* <Button
              className="max-w-xs mt-12"
              onClick={() => handleButtonClickSendForApprove}
              style={{
                backgroundColor: "#F18D05",
                display: handleSendForApprovalDisplay() ? "block" : "none", // Different color
              }}
            >
              Send for Approval
            </Button>

            <Button
              className="max-w-xs mt-12"
              onClick={() => handleButtonClickSendForSignAuth}
              style={{
                backgroundColor: "#F18D05",
                display: handleSendForSignatureDisplay() ? "block" : "none", // Different color
              }}
            >
              Send for Signature
            </Button> */}
            {/* <Button
              onClick={() =>
                setTasksConfig({
                  ...tasksConfig,
                  taskFormVisible: true,
                })
              }
              className="max-w-xs mt-12 ml-12"
              style={{
                backgroundColor: "#2ECC71",
              }}
            >
              Add Task
            </Button> */}
          </div>
        </div>
        

        {modal && (
          <Modal
            overflow="inside"
            opened
            onClose={() => {
              setTasksConfig({
                ...tasksConfig,
                loadTask: null,
              });
            }}
            size="calc(80vw)"
          >
            {/* <TaskDetailsUI
             title={title}
             parent={parent}
             parentId={parentId}
             taskId={tasksConfig.loadTask}
             onModalExit={() => {
               setTasksConfig({
                 ...tasksConfig,
                 loadTask: null,
               });
             }}
           /> */}
          </Modal>
        )}
        

        
      </div>
      </>}
      <div className="flex flex-row justify-between">
          {loading ? (
            <BeatLoader color={colors.primary} size={10} />
          ) : (
            <>
              <Button
                className="max-w-xs mt-20"
                onClick={savePrev}
                style={{
                  backgroundColor: "#46BDE1",
                }}
              >
                previous
              </Button>
              <Button
                className="max-w-xs mt-20"
                onClick={saveNext}
                style={{
                  backgroundColor: "#46BDE1",
                }}
              >
                finish
              </Button>
            </>
          )}
        </div>

    </>
  );
};

export default AgreementForm4;
