import React, { useState, useEffect } from "react";
import { Button, Checkbox, MultiSelect, Select, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { BeatLoader } from "react-spinners";
import {
  loadingStates,
  validateEmail,
  validateMobile,
} from "../../utilities/utilities";
import colors from "../../utilities/design";
import { getUserRolesUsingRoleId } from "../../utilities/apis/serviceProvider";
import {
  getAgreementDetails,
  getuserIdbyMail,
  putflowJson,
} from "../../utilities/apis/agreements";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";

const mapping = {
  Initiator: "3",
  Editor: "4",
  Reviewer: "5",
  Approver: "6",
  SigningAuthority: "7",
};


const AgreementForm5 = ({flowData,
  setFlowData, form, setUiConfigs, uiConfigs, save, id }) => {
  const currentUser = useSelector(selectUserData);
  const [isLoading, setIsLoading] = useState(true);
  const [userRolesIdData, setUserRolesIdData] = useState({
    userRoles: {
      Initiator: [],
      Editor: [],
      Reviewer: [],
      Approver: [],
      SigningAuthority: [],
    },
  });
  const [checked, setChecked] = useState(false);
  const [dataRole, setDataRole] = useState(flowData);
  console.log("data role: ", dataRole);
  useEffect(()=>{
    setDataRole({...dataRole, "sendfordirectsign": checked});
    // form.setValues((prevValues) => {
    //   const currentValues = flat.unflatten(prevValues);

    //   // Check if firstParty.name exists
    //   if (!currentValues.firstParty?.name) {
    //     return flat({
    //       ...currentValues,
    //       firstParty: {
    //         address: orgDetails.address,
    //         corporateOfficeAddress: budetails.copAddress,
    //         email: budetails.signEmail,
    //         entityType: orgDetails.entityType,
    //         name: orgDetails.name,
    //         representative: budetails.poc,
    //         signatory: budetails.signName,
    //         type: "Entity",
    //       },
    //     });
    //   }
    // });
  }, [checked]);
console.log(form);
  useEffect(() => {
    const fetchData = async () => {
      const agreementId = id;
      const { data } = await getAgreementDetails({ agreementId });
      const fetchedDataRole = data.agreement.flowJson;

      if (fetchedDataRole) {
        setDataRole(fetchedDataRole);
      } else {
        const loginAsId = Number(currentUser.loginAs.id); // Ensure it's a number
        if (loginAsId === 4 || loginAsId === 3) {
          const selectedData = [
            {
              userId: currentUser.id,
              isComplete: checked? true: false,
            },
          ];
          setDataRole((prevDataRole) => ({
            ...prevDataRole,
            [loginAsId + ""]: selectedData,
          }));
        }
      }
    };

    fetchData();
  }, [id, checked, currentUser.loginAs.id]);


  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        const roles = {
          Initiator: (await getUserRolesUsingRoleId(3)).data.userList,
          Editor: (await getUserRolesUsingRoleId(4)).data.userList,
          Reviewer: (await getUserRolesUsingRoleId(5)).data.userList,
          Approver: (await getUserRolesUsingRoleId(6)).data.userList,
          SigningAuthority: (await getUserRolesUsingRoleId(7)).data.userList,
        };

        setUserRolesIdData((prevData) => ({
          ...prevData,
          userRoles: roles,
        }));
      } catch (error) {
        console.error("Error fetching user roles:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserRoles();
  }, []);



  const savePrev = async () => {
    if (true) {

      setUiConfigs({
        ...uiConfigs,
        currentStep: 2,
      });
    }
  };

  const saveNext = async () => {
    if ((checked &&
      (!dataRole["7"] || dataRole["7"].length === 0 || dataRole["7"].length > 2)) ||
      (!checked &&
        (!dataRole["4"] || dataRole["4"].length === 0 ||
          !dataRole["7"] || dataRole["7"].length === 0 || dataRole["7"].length > 2))) {
      showNotification({
        color: "red",
        title: "Agreement Form",
        message:
          "Please fill in all required fields and ensure maximum 2 users for Signing Authority.",
      });
      return;
    }

    // form.setFieldValue("flowJson", dataRole);
    // await save({ ...form.values, formCompleted: true });
    // await apiWrapWithErrorWithData(putflowJson(id, dataRole));
    setUiConfigs({
      ...uiConfigs,
      currentStep: 4,
    });
  };
  const updateClick = async () => {
    if ((checked &&
      (!dataRole["7"] || dataRole["7"].length === 0 || dataRole["7"].length > 2)) ||
      (!checked &&
        (!dataRole["4"] || dataRole["4"].length === 0 ||
          !dataRole["7"] || dataRole["7"].length === 0 || dataRole["7"].length > 2))) {
      showNotification({
        color: "red",
        title: "Agreement Form",
        message:
          "Please fill in all required fields and ensure maximum 2 users for Signing Authority.",
      });
      return;
    }

    // form.setFieldValue("flowJson", dataRole);
    // await save({ ...form.values, formCompleted: true });
    try {
      await apiWrapWithErrorWithData(putflowJson(id, dataRole));
      showNotification({
        color: "green",
        title: "Update User Roles",
        message:
          "User Roles Updated.",
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
//     console.log("form: ", form.values["firstParty.email"] );
//     const getuserId = async()=>{
//       if(form.values["firstParty.email"]){
//       const resp = await apiWrapWithErrorWithData(getuserIdbyMail({email: form.values["firstParty.email"]}));
//       if(resp.success){
//         console.log("succcess hu thaa bhai ");
//         const data = [{userId: resp.id, isComplete : false}]
//         setDataRole((prevDataRole) => ({
//           ...prevDataRole,
//           ["7"]: data,
//         }));
//       }
//       }
//     }
// useEffect(()=>{
//   getuserId();
// }, [])

  const handleSelectChange = (role, selectedUser) => {
    const mappingRole = mapping[role];
    const selectedData = selectedUser
      ? [
        {
          userId: selectedUser,
          isComplete: false,
        },
      ]
      : [];

    setDataRole((prevDataRole) => ({
      ...prevDataRole,
      [mappingRole]: selectedData,
    }));
  };

  console.log(currentUser);





  const handleSelectDeselect = (role) => {
    const mappingRole = mapping[role];
    setDataRole((prevDataRole) => ({
      ...prevDataRole,
      [mappingRole]: [],
    }));
  };
  const handleMultiSelectChange = (role, selectedUsers) => {
    const mappingRole = mapping[role];

    const selectedData = selectedUsers.map((userId) => {
      const existingUser = dataRole[mappingRole]?.find(
        (user) => user.userId === userId
      );
      return {
        userId,
        isComplete: existingUser ? existingUser.isComplete : false,
      };
    });

    setDataRole((prevDataRole) => ({
      ...prevDataRole,
      [mappingRole]: selectedData,
    }));
  };


  console.log(dataRole);
  console.log(checked);

  return (
    <div className="w-full flex flex-col my-4">

      {currentUser.loginAs.id === 3 && <Checkbox
        checked={checked}
        label="send for signature directly"
        onChange={(event) => setChecked(event.currentTarget.checked)}
      />}
      <div className="grid grid-cols-3 gap-4">
        {isLoading ? (
          <div className="col-span-3 flex justify-center">
            <BeatLoader color={colors.primary} size={10} />
          </div>
        ) : (
          <>

            <Select
              key="Initiator"
              label="initiator"
              className="mt-8"
              placeholder="initiator"
              value={dataRole["3"]?.[0]?.userId || null}
              onChange={(selectedUser) =>
                handleSelectChange("Initiator", selectedUser)
              }
              data={userRolesIdData.userRoles.Initiator.map((user) => ({
                value: user.id,
                label: user.name,
              }))}
              allowDeselect
              onDeselect={() => handleSelectDeselect("Initiator")}
            />
            {!checked && <Select
              key="Editor"
              label="editor"
              className="mt-8"
              placeholder="editor"
              value={dataRole["4"]?.[0]?.userId}
              onChange={(selectedUser) =>
                handleSelectChange("Editor", selectedUser)
              }
              data={userRolesIdData.userRoles.Editor.map((user) => ({
                value: user.id,
                label: user.name,
              }))}
              allowDeselect
              onDeselect={() => handleSelectDeselect("Editor")}
              required={!checked}
            />}
            {!checked && <MultiSelect
              key="Reviewer"
              label="reviewer/s"
              className="mt-8"
              placeholder="reviewer/s"
              value={dataRole["5"]?.map(({ userId }) => userId) || []}
              onChange={(selectedUsers) =>
                handleMultiSelectChange("Reviewer", selectedUsers)
              }
              data={userRolesIdData.userRoles.Reviewer.map((user) => ({
                value: user.id,
                label: user.name,
              }))}
            />}
            {!checked && <MultiSelect
              key="Approver"
              label="approver/s"
              className="mt-8"
              placeholder="approver/s"
              value={dataRole["6"]?.map(({ userId }) => userId) || []}
              onChange={(selectedUsers) =>
                handleMultiSelectChange("Approver", selectedUsers)
              }
              data={userRolesIdData.userRoles.Approver.map((user) => ({
                value: user.id,
                label: user.name,
              }))}
            />}
            {/* <MultiSelect
              key="SigningAuthority"
              label="signing authority"
              className="mt-8"
              placeholder="signing authority"
              value={dataRole["7"]?.map(({ userId }) => userId) || []}
              onChange={(selectedUsers) =>
                handleMultiSelectChange("SigningAuthority", selectedUsers)
              }
              data={userRolesIdData.userRoles.SigningAuthority.map((user) => ({
                value: user.id,
                label: user.name,
              }))}
              maxSelectedValues={2}
              required
            /> */}
            <div></div>
            {uiConfigs.loading !== loadingStates.LOADING && (
              <div className="flex justify-end">
              <Button
                className="mt-14 align-right "
                onClick={updateClick}
                color="green"
              >
                update
              </Button></div>)}
          </>

        )}
      </div>
      <div className="flex flex-row justify-between">
        {uiConfigs.loading === loadingStates.LOADING ? (
          <BeatLoader color={colors.primary} size={10} />
        ) : (
          <>

            <Button
              className="max-w-xs mt-20"
              onClick={savePrev}
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              previous
            </Button>
            <div className="flex flex-row">

              <Button
                className="max-w-xs mt-20"
                onClick={saveNext}
                style={{
                  backgroundColor: "#46BDE1",
                }}
              >
                next
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AgreementForm5;
