/** @format */

import colors from "../utilities/design";

import React from "react";
import { Button, Modal } from "@mantine/core";
import { BeatLoader } from "react-spinners";

const DeleteConfirmationModal = ({
  open,
  setOpen,
  deleteLoading,
  deleteHandler,
  text
}) => (
  <Modal
    overflow="inside"
    closeOnClickOutside={false}
    size="calc(30vw)"
    opened={open}
    onClose={() => setOpen(!open)}
    // title="Delete Agreement"
  >
    <div className="">
      <h4>{text}</h4>
      <div className="flex flex-row justify-end">
        <Button
          style={{
            backgroundColor: colors.primary,
            borderRadius: "0.5rem",
            color: "#F5F5F5",
          }}
          onClick={() => setOpen(false)}
        >
          cancel
        </Button>
        <Button
          disabled={deleteLoading}
          className="ml-4"
          style={{
            backgroundColor: deleteLoading ? "#ffcccc" : "#ff4d4d",
            borderRadius: "0.5rem",
            color: "#F5F5F5",
          }}
          onClick={() => deleteHandler()}
        >
          {deleteLoading ? (
            <BeatLoader size={10} color={colors.rejected} />
          ) : (
            "delete"
          )}
        </Button>
      </div>
    </div>
  </Modal>
);

export default DeleteConfirmationModal;
