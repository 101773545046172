import React, { useEffect, useState } from "react";
import {
  AppShell,
  Header,
  Navbar,
  ActionIcon,
  ScrollArea,
} from "@mantine/core";
import {
  Notes,
  Signature,
  Pencil,
  Lock,
  Subtask,
  Home,
  Paperclip,
  Bell,
  
  Plus,
  Search,
  Template,
  LayoutSidebarLeftCollapse,
  LayoutSidebarRightCollapse,
  Snowman,
  ReportMoney,
  Mail,
  File
} from "tabler-icons-react";
// import {
//   Notes, Home, Paperclip,
//   Bell, BarrierBlock, Template,
//   LayoutSidebarLeftCollapse, LayoutSidebarRightCollapse, Snowman, ReportMoney,
// } from 'tabler-icons-react';
import { LinksGroup } from "../../components/NavbarLinkGroup";
import AppHeader from "../../components/Header/AppHeader";
import HamburgerMenuAgreements from "../HamburgerMenuAgreements";
import { selectUserData } from "../../redux/selectors";
import { useSelector } from "react-redux";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getUnseenNotifications } from "../../utilities/apis/users";

const MainAppAgreementsManager = ({ appContent, dm=false }) => {
  const currentUser = useSelector(selectUserData) || {};
  const [unseenCount, setUnseenCount] = useState(0);
  const roles = currentUser.userRole || [];
  console.log("currentUser: ", currentUser.id);
  useEffect(()=>{
    const getUnseenNotificationCount = async() =>{
      const response = await apiWrapWithErrorWithData(getUnseenNotifications());
      if(response.success){
        setUnseenCount(response.count);
      }
    }
    getUnseenNotificationCount();
  }, []);
  const loginAs = currentUser.loginAs;

  const mockdata = [
    !dm ?{
      label: "dashboard",
      icon: Home,
      link: "/app/agreements_manager",
    }: null,
    !dm? {
      label: `notifications`,
      icon: Bell,
      link: "/app/agreements_manager/notifications",
      hasNumber : `${unseenCount}`,
    }: null,

    !dm ?{
      label: "my agreements",
      icon: Paperclip,
      link: "/app/agreements_manager/agreements-list",
    }: null,
    (!dm && (loginAs.id === 3 || loginAs.id === 4 || loginAs.id === 1 || loginAs.id === 2))
      ? {
          label: "new agreement",
          icon: Plus,
          initiallyOpened: false,
          links:[
            {
              label: "individual agreement",
              link: "/app/agreements_manager/agreements/new",
            },
            {
              label: "bulk agreements",
              link: "/app/agreements_manager/agreements/new-bulk",
            },
            {
              label: "legacy agreement",
              link: "/app/agreements_manager/agreements/new-legacy-agreement",
            },
            {
              label: "bulk legacy agreements",
              link: "/app/agreements_manager/agreements/bulk-legacy-agreement",
            },
          ]
        }
      : null,
    // loginAs.id === 5 || loginAs.id === 6
    //   ? {
    //       label: "Review",
    //       icon: Search,
    //       link: "/app/agreements_manager/review",
    //     }
    //   : null,
    // loginAs.id === 1 || loginAs.id === 4
    //   ? {
    //       label: "Signature",
    //       icon: Pencil,
    //       link: "/app/agreements_manager/signature",
    //     }
    //   : null,
    (!dm && (loginAs.id === 1 || loginAs.id === 4 || loginAs.id === 2 ||loginAs.id === 3))
      ? {
          label: "docs locker",
          icon: Lock,
          link: "/app/agreements_manager/docs-locker",
        }
      : null,
      dm ? {
        label:"documents",
        // initiallyOpened: false,
        icon: File,
        link: "/app/corporatedocuments",
      //   links:[
      //   {
      //     label: "corporate documents",
      //     link: "/app/agreements_manager/corporatedocuments",
      //   },
      //   // {
      //   //   label: "my documents",
      //   //   link: "/app/agreements_manager/mydocuments",
      //   // },
      // ]
      }: null,
      // !dm ? {
      //   label:"emails",
      //   initiallyOpened: false,
      //   icon: Mail,
      //   links:[
      //   {
      //     label: "inbox",
      //     link: "/app/agreements_manager/emails/inbox",
      //   },
      //   {
      //     label: "outbox",
      //     link: "/app/agreements_manager/emails/outbox",
      //   },]
      // }: null,
       
    (!dm &&  (loginAs.id === 1 || loginAs.id === 2 || loginAs.id === 4))
      ? {
          label: "templates",
          icon: Template,
          initiallyOpened: true,
          links: [
            {
              label: "agreements",
              link: "/app/agreements_manager/templates/agreement",
            },
            {
              label: "clauses",
              link: "/app/agreements_manager/templates/clause",
            },
          ],
        }
      : null,
    //   loginAs.id === 1 || loginAs.id === 2 || loginAs.id === 4?
    // {
    //   label: "obligations",
    //   icon: Subtask,
    //   initiallyOpened: true,
    //   link: "https://obligationapp.truecounsel.in/",

    // }: null,

    loginAs.id === 1 || loginAs.id === 2
      ? {
          label: "team manager",
          icon: Snowman,
          link: "/app/agreements_manager/service-providers",
        }
      : null,
  ].filter(Boolean);

  const links = mockdata.map((item) => (
    <LinksGroup {...item} key={item.label} />
  ));

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar
          style={{ backgroundColor: "#F1F4FA", padding: "10px 0 0 0" }}
          width={{ base: sidebarCollapsed ? 50 : 250 }}
          height="calc(100vh - 65px)"
          p="xs"
        >
          <Navbar.Section grow component={ScrollArea}>
            <div className="flex flex-col">
              <div className="flex justify-end">
                <ActionIcon
                  color="blue"
                  onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
                  style={{ cursor: "pointer", marginRight: "9%" }}
                >
                  {sidebarCollapsed ? (
                    <LayoutSidebarRightCollapse color="#46BDE1" />
                  ) : (
                    <LayoutSidebarLeftCollapse color="#46BDE1"/>
                  )}
                </ActionIcon>
              </div>
              {!sidebarCollapsed && <div>{links}</div>}
            </div>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={65}>
          <AppHeader />
        </Header>
      }
    >
      <div
        style={{ backgroundColor: "#FFFFFF", minHeight: "calc(100vh - 100px)" }}
      >
        {appContent}
      </div>
    </AppShell>
  );
};

export default MainAppAgreementsManager;
