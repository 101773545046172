import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Modal,
  Select,
  Skeleton,
  Text,
  TextInput,
} from "@mantine/core";
import {Search} from "tabler-icons-react";
import flat from "flat";
import { showNotification } from "@mantine/notifications";
import { BeatLoader } from "react-spinners";
import {
  loadingStates,
  validateEmail,
  validateMobile,
} from "../../utilities/utilities";
import colors from "../../utilities/design";
import AgreementListModal from "./AgreementListModal";

const AgreementForm1 = ({ form, setUiConfigs, uiConfigs, save }) => {
  const agreementFormJson = flat.unflatten(form.values);

  // console.log(agreementFormJson);
  // console.log(agreementFormJson.relationshipWith);

  const saveNext = async () => {
    const keys = {};
      if (
        !agreementFormJson?.relationshipType ||
        agreementFormJson?.relationshipType?.length < 3
      ) {
        keys.typeOfDocument = "type of document is mandatory.";
      }

    if (
      !agreementFormJson?.typeOfAgreement ||
      agreementFormJson?.typeOfAgreement?.length < 3
    ) {
      keys.typeOfAgreement = "type is mandatory.";
    }
    if (
      !agreementFormJson?.titleOfAgreement ||
      agreementFormJson?.titleOfAgreement?.length < 3
    ) {
      keys.titleOfAgreement = "title is mandatory.";
    }
    if (
      !agreementFormJson?.titleOfAgreement ||
      agreementFormJson?.titleOfAgreement?.length > 50
    ) {
      keys.titleOfAgreement =
        "the title for the agreement should stay within 50 characters or less.";
    }

    if (agreementFormJson?.requestingUser?.name?.length < 3) {
      keys["requestingUser.name"] = "please enter 3 or more characters.";
    }
    if (agreementFormJson?.requestingUser?.email?.length > 0) {
      if (!validateEmail(agreementFormJson?.requestingUser?.email)) {
        keys["requestingUser.email"] = "please enter correct email.";
      }
    } else {
      keys["requestingUser.email"] = "please enter correct email.";
    }
    // if (agreementFormJson?.requestingUser?.phone?.length > 0) {
    //   if (
    //     !validateMobile(agreementFormJson?.requestingUser?.phone) ||
    //     agreementFormJson?.requestingUser?.phone.length !== 10
    //   ) {
    //     keys["requestingUser.phone"] = "Please enter 10 digits phone number.";
    //   }
    // } else {
    //   keys["requestingUser.phone"] = "Please enter 10 digits phone number.";
    // }
    console.log("Validation errors:", keys);
    if (Object.keys(keys).length > 0) {
      form.setErrors(keys);
      showNotification({
        color: "red",
        title: "agreement form",
        message: "please check all fields are filled properly.",
      });
    } else {
      await save();
      setUiConfigs({
        ...uiConfigs,
        currentStep: 1,
      });
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState(0);
 

  const handleSearchClick = () => {
    // Set the showModal state to true when the button is clicked
    setShowModal(true);
  };

  const handleCloseModal = () => {
    // Set the showModal state to false when the modal is closed
    setShowModal(false);
  };
  const typeOfDocuments = [
    "Parent",
    "Supplementary",
    "Supplementary to Supplementary",
  ];
  console.log(selectedAgreement);
  useEffect(()=>{
    if (selectedAgreement) {
      form.setFieldValue("relationshipWith", `${selectedAgreement}`);
    };
  }, [selectedAgreement]);
  

  return (
    <div className="w-full flex flex-col my-4">
      <div className="grid grid-cols-2">
        <div>
        <Select
          required
          className="max-w-lg"
          label="type of document"
          placeholder="select type of document"
          data={typeOfDocuments.map((value) => ({ value, label: value }))}
          style={{maxWidth:"82%"}}
          {...form.getInputProps("relationshipType")}
          onCreate={(query) => {
            form.setFieldValue(
              "relationshipType",
              Array.from(
                new Set([...(form.values.relationshipType || []), query])
              )
            );
          }}
        />
        </div>
        <div style={{maxWidth: "82%"}} className="flex">
        <TextInput
          label="connected agreement"
          placeholder="enter agreement number"
          {...form.getInputProps("relationshipWith")}
          required={
            agreementFormJson.relationshipType === "Supplementary" ||
            agreementFormJson.relationshipType ===
              "Supplementary to Supplementary"
          }
          type="number"
          onChange={(event) => {
            // const value = event.currentTarget.value;
            // const commaSeparatedValues = value.split(",").map((v) => v.trim());
            form.setFieldValue("relationshipWith", event.currentTarget.value);
          }}
          style={{width:"88%", display: "inline-block", marginRight: "2%"}}
        />
        <Button
          className="max-w-lg"
          color="#46BDE1"
          onClick={handleSearchClick} // Call the handler on button click
          style={{width:"10%", display: "inline-block", marginBottom: "0", marginTop: "auto", padding:"0", backgroundColor: "#46BDE1"}}
        >
          <Search size={20} strokeWidth={3} />
        </Button>
        </div>
      </div>
      {/* <div className="grid grid-cols-2">
        <div></div>
        <Button
          className="mt-2 max-w-lg"
          color="green"
          onClick={handleSearchClick} // Call the handler on button click
          style={{maxWidth:"42%"}}
        >
          Search for Agreements
        </Button>
      </div> */}
      {agreementFormJson.typeOfAgreementData.length === 0 ||
      agreementFormJson.typeOfAgreementData.length ? (
        <div className="grid grid-cols-2 mt-4">
          <Select
            searchable
            required
            creatable
            className="max-w-lg"
            style={{maxWidth:"82%"}}
            label="agreement category"
            placeholder="select agreement category"
            data={agreementFormJson.typeOfAgreementData}
            getCreateLabel={(query) => `+ add ${query}`}
            onCreate={(query) => {
              form.setFieldValue(
                "typeOfAgreementData",
                Array.from(
                  new Set([...(form.values.typeOfAgreementData || []), query])
                )
              );
            }}
            {...form.getInputProps("typeOfAgreement")}
          />
          <Select
            searchable
            required
            creatable
            className="max-w-lg"
            label="agreement name"
            placeholder="select agreement name"
            style={{maxWidth:"82%"}}
            data={agreementFormJson.titleOfAgreementData}
            getCreateLabel={(query) => `+ add ${query}`}
            onCreate={(query) => {
              form.setFieldValue(
                "titleOfAgreementData",
                Array.from(
                  new Set([...(form.values.titleOfAgreementData || []), query])
                )
              );
            }}
            {...form.getInputProps("titleOfAgreement")}
          />
        </div>
      ) : (
        <Skeleton height={50} />
      )}

      {showModal && (
        <Modal
          overflow="inside"
          opened
          onClose={handleCloseModal} // Call the handler on modal close
          size="calc(80vw)"
        >
          <AgreementListModal onCloseAgreement={handleCloseModal} setSelectedAgreement={setSelectedAgreement} />
        </Modal>
      )}

      <Divider className="mt-8" />
      <Text size="md" color="#46BDE1" className="my-8">
        requesting user details
      </Text>
      <div className="grid grid-cols-3 gap-4">
        <TextInput
          required
          label="name"
          placeholder="enter name"
          {...form.getInputProps("requestingUser.name")}
        />
        <TextInput
          required
          label="email"
          placeholder="enter email"
          {...form.getInputProps("requestingUser.email")}
        />
        {/* <TextInput
          label="Phone"
          placeholder="Enter 10 digit phone no."
          {...form.getInputProps("requestingUser.phone")}
        /> */}
        <TextInput
          label="designation"
          placeholder="enter designation"
          {...form.getInputProps("requestingUser.designation")}
        />
        <TextInput
          label="department"
          placeholder="enter department"
          {...form.getInputProps("requestingUser.businessDepartment")}
        />
      </div>
      <div className="flex flex-row justify-between">
        <div />
        {uiConfigs.loading === loadingStates.LOADING ? (
          <BeatLoader color={colors.primary} size={10} />
        ) : (
          <Button
            className="max-w-xs mt-20"
            style={{
              backgroundColor: "#46BDE1",
            }}
            onClick={saveNext}
          >
            next
          </Button>
        )}
      </div>
    </div>
  );
};

export default AgreementForm1;
